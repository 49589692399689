import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import {
    useCreateScholarshipMutation,
    useGetListScholarshipQuery,
    useLazyGetScholarshipByIdQuery,
    useUpdateScholarshipMutation,
} from "@redux/queries/admin/admin.scholarship";
import { Button, Card, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import Tab from "./Tab";
import { pickBy, identity } from "lodash";
import { useGetListTrainingQuery } from "@redux/queries/admin/admin.training";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useGetListScholarshipPolicyQuery } from "@redux/queries/admin/admin.scholarship.policy";
import { useLocation, useNavigate } from "react-router-dom";

const CreateScholarship = () => {
    const [form] = Form.useForm();

    const location = useLocation();
    const navigation = useNavigate();
    const id = location.pathname.split("/")[4];

    const [create, isLoadingCreate] = useCreateScholarshipMutation();
    const [update, isLoadingUpdate] = useUpdateScholarshipMutation();
    const [getInfoScholarship, { data: dataEdit, isLoading }] = useLazyGetScholarshipByIdQuery();

    const { showToast } = useToast();
    const {
        data: list_scholarship_type,
        isLoading: isLoadingScholarshipType,
        error: errorScholarshipType,
    } = useGetListScholarshipTypeQuery();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();
    const [disableBtn, setDisableBtn] = useState<boolean>(false);

    const { data: listScholarshipPolicy } = useGetListScholarshipPolicyQuery();

    const { data: list_train, isLoading: isLoadingTrain, error: errorCity } = useGetListTrainingQuery();

    const [listTrain, setListTrain] = useState<any[]>([]);

    const onChangeTrain = (value: any, index: any) => {
        const listTrainFilter = list_train?.filter((o: any) => o.scholarshipTypeId === value);
        const list = [...(listTrain || [])];
        list[index] = listTrainFilter;
        setListTrain(list);
    };

    useEffect(() => {
        if (id) {
            getInfoScholarship({ id });
        }
    }, [id]);

    useEffect(() => {
        if (dataEdit) {
            console.log("dataedit: ", dataEdit);
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            const shortContentVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            const listTrainingPolicies = dataEdit?.scholarshipTrainingPolicy?.map((item: any) => ({
                trainingId: item?.__training__?.id,
                ...item,
            }));
            form.setFieldsValue({
                ...dataEdit,
                titleVi: dataEdit?.language[0].title,
                titleEn: dataEdit?.language[1].title,
                titleCn: dataEdit?.language[2].title,
                slugVi: dataEdit?.language[0].slug,
                slugEn: dataEdit?.language[1].slug,
                slugCn: dataEdit?.language[2].slug,
                shortContentVi,
                shortContentEn,
                shortContentCn,
                introduceVi,
                introduceEn,
                introduceCn,
                scholarshipTypeId: dataEdit?.scholarshipType?.id,
                scholarshipTrainingPolicies: listTrainingPolicies,
            });
            setEditThumbnail(dataEdit?.thumbnail);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const handleSubmit = async () => {
        let values;
        try {
            values = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }

        setDisableBtn(true);

        const language = [
            {
                lang: "vi",
                title: values.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,
                content: values.introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
                shortContent:
                    values.shortContentVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                slug: values.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
            },
            {
                lang: "en",
                title: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                content: values.introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
                shortContent:
                    values.shortContentEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent,
                slug: values.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
            },
            {
                lang: "cn",
                title: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                content: values.introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
                shortContent:
                    values.shortContentCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                slug: values.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
            },
        ];

        const data = pickBy(
            {
                ...values,
                language,
                thumbnail: thumbnail || editThumbnail,
            },
            identity
        ) as any;

        console.log("data: ", data);

        const result = dataEdit
            ? await update({
                  id: dataEdit.id,
                  ...data,
                  passScore: parseInt(data.passScore),
              })
            : await create({
                  ...data,
                  passScore: parseInt(data.passScore),
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setEditThumbnail(null);
            setThumbnail(null);
            navigation("/admin/scholarship");
        }
    };

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <div>
                    <section id="content-main">
                        <div className="box-component">
                            <div className="body-component">
                                <Card
                                    title={
                                        <div className="d-flex gap-3">
                                            <ArrowLeftOutlined
                                                onClick={() => {
                                                    navigation("/admin/scholarship");
                                                }}
                                            />
                                            <h4>{dataEdit ? "Sửa học bổng" : "Thêm mới học bổng"}</h4>
                                        </div>
                                    }
                                    bordered={false}
                                    style={{ width: "100%" }}
                                    loading={isLoading}
                                >
                                    <Form
                                        form={form}
                                        initialValues={dataEdit}
                                    >
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Tab
                                                        form={form}
                                                        thumbnail={thumbnail}
                                                        setThumbnail={(value: any) => setThumbnail(value)}
                                                        introduceVi={introduceVi}
                                                        introduceEn={introduceEn}
                                                        introduceCn={introduceCn}
                                                        editThumbnail={editThumbnail}
                                                        setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                                        setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                                        setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                                        errorContent={errorContent}
                                                        setErrorContent={(value: any) => setErrorContent(value)}
                                                    />
                                                </div>
                                            </div>
                                            <Row gutter={20}>
                                                <Col span={12}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={"scholarshipTypeId"}
                                                                className="form-floating"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Thông tin bắt buộc",
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    allowClear
                                                                >
                                                                    {list_scholarship_type &&
                                                                        list_scholarship_type.map((o: any, i: any) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={o.id}
                                                                                    value={o.id}
                                                                                >
                                                                                    {o.language[0].title}
                                                                                </Select.Option>
                                                                            );
                                                                        })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Loại học bổng</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="passScore"
                                                                className="form-floating"
                                                                rules={[
                                                                    {
                                                                        pattern: /^[0-9]*$/,
                                                                        message: "Phải nhập số",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>Điểm đỗ</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={20}>
                                                <Col span={12}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="link"
                                                                className="form-floating"
                                                                rules={[
                                                                    {
                                                                        type: "url",
                                                                        message: "Sai định dạng url",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="no-icon form-floating"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>Link</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Form.List
                                                name="scholarshipTrainingPolicies"
                                                initialValue={[{}]}
                                            >
                                                {(fields, { add, remove }, { errors }) => (
                                                    <div
                                                        style={{ display: "flex", rowGap: 16, flexDirection: "column" }}
                                                    >
                                                        <Row>
                                                            {fields.map((field, index) => (
                                                                <Col
                                                                    span={12}
                                                                    key={field.key}
                                                                >
                                                                    <Card
                                                                        size="small"
                                                                        title={`Hệ đào tạo ${field.name + 1}`}
                                                                        extra={
                                                                            index > 0 ? (
                                                                                <CloseOutlined
                                                                                    onClick={() => {
                                                                                        remove(field.name);
                                                                                    }}
                                                                                />
                                                                            ) : null
                                                                        }
                                                                    >
                                                                        <div className="form-group">
                                                                            <div className="form-floating">
                                                                                <Form.Item
                                                                                    name={[field.name, "trainingId"]}
                                                                                    className="form-floating"
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                            message:
                                                                                                "Thông tin bắt buộc",
                                                                                        },
                                                                                        ({ getFieldValue }) => ({
                                                                                            validator(_, value) {
                                                                                                const listScholarshipTrainingPolicies =
                                                                                                    getFieldValue(
                                                                                                        "scholarshipTrainingPolicies"
                                                                                                    ) || [];
                                                                                                const listSelectedTraining =
                                                                                                    listScholarshipTrainingPolicies?.map(
                                                                                                        (o: any) =>
                                                                                                            o.trainingId
                                                                                                    );
                                                                                                if (
                                                                                                    value &&
                                                                                                    listSelectedTraining?.find(
                                                                                                        (
                                                                                                            trainId: string,
                                                                                                            indexTrain: number
                                                                                                        ) =>
                                                                                                            trainId ===
                                                                                                                value &&
                                                                                                            indexTrain !==
                                                                                                                index
                                                                                                    )
                                                                                                ) {
                                                                                                    return Promise.reject(
                                                                                                        "Hệ đào tạo đã được chọn"
                                                                                                    );
                                                                                                }
                                                                                                return Promise.resolve();
                                                                                            },
                                                                                        }),
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        className="form-control form-floating"
                                                                                        bordered={false}
                                                                                        showSearch
                                                                                        filterOption={(input, option) =>
                                                                                            (option?.children as any)
                                                                                                ?.toLowerCase()
                                                                                                .indexOf(
                                                                                                    input.toLowerCase()
                                                                                                ) >= 0
                                                                                        }
                                                                                    >
                                                                                        {list_train &&
                                                                                            list_train.map(
                                                                                                (o: any, i: any) => {
                                                                                                    return (
                                                                                                        <Select.Option
                                                                                                            key={o.id}
                                                                                                            value={o.id}
                                                                                                        >
                                                                                                            {
                                                                                                                o
                                                                                                                    .language[0]
                                                                                                                    .title
                                                                                                            }
                                                                                                        </Select.Option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                                <label>Hệ đào tạo</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <div className="form-floating">
                                                                                <Form.Item
                                                                                    name={[
                                                                                        field.name,
                                                                                        "scholarshipPolicyIds",
                                                                                    ]}
                                                                                    className="form-floating"
                                                                                    rules={[
                                                                                        {
                                                                                            required: false,
                                                                                            message:
                                                                                                "Thông tin bắt buộc",
                                                                                        },
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        className="form-control form-floating"
                                                                                        bordered={false}
                                                                                        showSearch
                                                                                        filterOption={(input, option) =>
                                                                                            (option?.children as any)
                                                                                                ?.toLowerCase()
                                                                                                .indexOf(
                                                                                                    input.toLowerCase()
                                                                                                ) >= 0
                                                                                        }
                                                                                        mode="multiple"
                                                                                    >
                                                                                        {listScholarshipPolicy &&
                                                                                            listScholarshipPolicy.map(
                                                                                                (o: any, i: any) => {
                                                                                                    return (
                                                                                                        <Select.Option
                                                                                                            key={o.id}
                                                                                                            value={o.id}
                                                                                                        >
                                                                                                            {
                                                                                                                o
                                                                                                                    .language[0]
                                                                                                                    .title
                                                                                                            }
                                                                                                        </Select.Option>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </Select>
                                                                                </Form.Item>
                                                                                <label>Cơ cấu học bổng</label>
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            ))}
                                                        </Row>

                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            block
                                                        >
                                                            + Thêm hệ đào tạo
                                                        </Button>
                                                    </div>
                                                )}
                                            </Form.List>

                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="w-100 mt-5"
                                                    onClick={() => handleSubmit()}
                                                    disabled={disableBtn}
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default CreateScholarship;
