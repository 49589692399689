import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { Button, Col, Form, Input, Row, Select, Space } from "antd";
import React from "react";

interface IProps {
    data: any;
    onSearch: (data: any) => void;
    onClear: (v: boolean) => void;
}

const FilterTitleSchoolType: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch(values);
    };

    const handleClear = () => {
        const title = form.getFieldValue("title");
        if (title) {
            form.resetFields();
            props.onClear(true);
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={8}>
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name="title"
                                            className="form-floating"
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tiêu đề</label>
                                    </div>
                                </div>
                                <Row justify="end">
                                    <Space>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                            >
                                                Search
                                            </Button>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button onClick={() => handleClear()}>Clear</Button>
                                        </Form.Item>
                                    </Space>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
};

export default FilterTitleSchoolType;
