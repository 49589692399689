import { Col, Form, Input, Row, Select } from "antd";
import { SchoolAttributeLabels } from "../schoolAttribute.enum";

interface IAttributeTabProps {
    language: string;
}

const AttributeTab = ({ language }: IAttributeTabProps) => {
    const listArea = [
        { label: "Hoa Bắc", value: "Hoa Bắc" },
        { label: "Hoa Nam", value: "Hoa Nam" },
        { label: "Hoa Đông", value: "Hoa Đông" },
        { label: "Hoa Tây", value: "Hoa Tây" },
        { label: "Hoa Trung", value: "Hoa Trung" },
        { label: "Tây Nam", value: "Tây Nam" },
        { label: "Đông Bắc", value: "Đông Bắc" },
        { label: "Tây Bắc", value: "Tây Bắc" },
    ];
    return (
        <>
            <div
                className="tab-pane fade show"
                id="social-tab-pane"
                role="tabpanel"
                aria-labelledby="social-tab"
                tabIndex={0}
            >
                <Row>
                    <Col span={8}>
                        <div className="row">
                            {/* col 1 */}
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "student"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Số lượng sinh viên</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "international_student"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Số sinh viên nước ngoài</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "cityLevel"]}
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    placeholder=""
                                                >
                                                    <Select.Option value={1}>1</Select.Option>
                                                    <Select.Option value={2}>2</Select.Option>
                                                    <Select.Option value={3}>3</Select.Option>
                                                    <Select.Option value={4}>4</Select.Option>
                                                    <Select.Option value={5}>5</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <label>Cấp thành phố</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "is_central_city"]}
                                                className="form-floating"
                                            >
                                                <Select
                                                    className="form-control form-floating"
                                                    bordered={false}
                                                    placeholder=""
                                                >
                                                    <Select.Option value={"true"}>Đúng</Select.Option>
                                                    <Select.Option value={"false"}>Không</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <label>Thành phố trực thuộc TW</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "globalRank"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["globalRank"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "facility"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["facility"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "exerciseYard"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["exerciseYard"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "school_type"]}
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Loại trường</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "number_of_majors"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số chuyên ngành</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "area"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        placeholder=""
                                                    >
                                                        {listArea.map((item, index) => (
                                                            <Select.Option
                                                                key={index}
                                                                value={item.value}
                                                            >
                                                                {item.label}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <label>Khu vực</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "economic_zone"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        placeholder=""
                                                    >
                                                        <Select.Option value={"true"}>Đúng</Select.Option>
                                                        <Select.Option value={"false"}>Không</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>Đặc khu kinh tế</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "tuitionForAYear"]}
                                                        className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["tuitionForAYear"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "tuitionFeeForMaster"]}
                                                        className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["tuitionFeeForMaster"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "tuitionFeeForPhD"]}
                                                        className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["tuitionFeeForPhD"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="row">
                            {/* col 1 */}
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "year_of_school_establishment"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Năm thành lập</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "school_level"]}
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Cấp bậc trường</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "provinceLevel"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Top Tỉnh</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "national_ranking"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Xếp hạng quốc gia</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "laboratory"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["laboratory"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "library"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["library"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "governmentScholarship"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        placeholder=""
                                                    >
                                                        <Select.Option value={"true"}>Có</Select.Option>
                                                        <Select.Option value={"false"}>Không</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["governmentScholarship"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "number_of_lecturers"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số lượng giảng viên</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "acreage"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Diện tích (m2)</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "numberOfScholarshipTypes"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số loại học bổng</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "number_of_specialized"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số lượng chuyên ngành</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "confuciusScholarship"]}
                                                        className="form-floating"
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            placeholder=""
                                                        >
                                                            <Select.Option value={"true"}>Có</Select.Option>
                                                            <Select.Option value={"false"}>Không</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["confuciusScholarship"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "provincialScholarship"]}
                                                        className="form-floating"
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            placeholder=""
                                                        >
                                                            <Select.Option value={"true"}>Có</Select.Option>
                                                            <Select.Option value={"false"}>Không</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["provincialScholarship"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "cityScholarship"]}
                                                        className="form-floating"
                                                    >
                                                        <Select
                                                            className="form-control form-floating"
                                                            bordered={false}
                                                            placeholder=""
                                                        >
                                                            <Select.Option value={"true"}>Có</Select.Option>
                                                            <Select.Option value={"false"}>Không</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["cityScholarship"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="row">
                            {/* col 1 */}
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "tuition_fee_university"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Học phí hệ Đại học</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "tuition_fee_master"]}
                                                className="form-floating"
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Học phí hệ Thạc sĩ</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "dormitory_fee"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Phí kí túc xá</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-floating">
                                            <Form.Item
                                                name={[language, "top_specialized"]}
                                                className="form-floating"
                                                // rules={[
                                                //     {
                                                //         pattern: /^[0-9\b]+$/,
                                                //         message: "Phải nhập số",
                                                //     },
                                                // ]}
                                            >
                                                <Input
                                                    type="text"
                                                    className="form-floating no-icon"
                                                    placeholder=""
                                                />
                                            </Form.Item>
                                            <label>Top ngành</label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "schoolScholarship"]}
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        placeholder=""
                                                    >
                                                        <Select.Option value={"true"}>Có</Select.Option>
                                                        <Select.Option value={"false"}>Không</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["schoolScholarship"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "typeOfSchool"]}
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["typeOfSchool"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "collegeStudent"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>{SchoolAttributeLabels["collegeStudent"]}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="body-component">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "living_expense_fee"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Sinh hoạt phí</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "major_focus"]}
                                                    className="form-floating"
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Ngành trọng điểm</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[language, "dining_hall_number"]}
                                                    className="form-floating"
                                                    // rules={[
                                                    //     {
                                                    //         pattern: /^[0-9\b]+$/,
                                                    //         message: "Phải nhập số",
                                                    //     },
                                                    // ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="form-floating no-icon"
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                                <label>Số nhà ăn</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "postgraduate"]}
                                                        // className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["postgraduate"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "masterMajor"]}
                                                        className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["masterMajor"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "phDMajor"]}
                                                        className="form-floating"
                                                        // rules={[
                                                        //     {
                                                        //         pattern: /^[0-9\b]+$/,
                                                        //         message: "Phải nhập số",
                                                        //     },
                                                        // ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>{SchoolAttributeLabels["phDMajor"]}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "metropolitan_scale"]}
                                                        className="form-floating"
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Quy mô thành phố</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={[language, "province"]}
                                                        className="form-floating"
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Tỉnh</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AttributeTab;
