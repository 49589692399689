import { CloseOutlined } from "@ant-design/icons";
import blankImage from "@assets/images/blank.png";
import TinyMCE from "@components/Editor";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { useGetListSchoolCategoryQuery } from "@redux/queries/admin/admin.school.category";
import { useLazyGetListSchoolScholarshipTrainingQuery } from "@redux/queries/admin/admin.school.scholarship.training";
import { useLazyGetListSchoolSpecializedQuery } from "@redux/queries/admin/admin.school.specialized";
import { useLazyGetListScholarshipBySchoolIdQuery, useLazyScholarshipTypeQuery } from "@redux/queries/user/public.api";
import store from "@redux/store";
import { validateNoSpaces, validateSlug } from "@utils/common";
import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Tabs,
    Tag,
    UploadFile,
    message,
} from "antd";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import "jodit";
import React, { useEffect, useRef, useState } from "react";
import {
    ADMISSION_PERIOD_OPTIONS,
    PROGRAMS_LANGUAGE,
    RELATES,
    RELATES_OPTIONS,
    SUPPORT_MODE_OPTIONS,
    TRAINING_FORMAT,
    TYPE_OF_ADMISSION_OPTIONS,
} from "../TypeInput";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    thumbnail?: string;
    setThumbnail?: (value: string) => void;
    editThumbnail?: string;

    form: any;
    errorContent?: string;
    setErrorContent?: (value: string) => void;

    dataEdit?: any;

    setListMajor?: (values: any) => void;
    setCheckedList?: (values: any) => void;

    setHighLever?: (bool: boolean) => void;

    tagsVi?: { key: number; value: string }[];
    tagsEn?: { key: number; value: string }[];
    tagsCn?: { key: number; value: string }[];
    setTagsVi?: (value: { key: number; value: string }[]) => void;
    setTagsEn?: (value: { key: number; value: string }[]) => void;
    setTagsCn?: (value: { key: number; value: string }[]) => void;
}

const CheckboxGroup = Checkbox.Group;

const GeneralTab: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        introduceVi,
        introduceEn,
        introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        form,
        thumbnail,
        setThumbnail,
        editThumbnail,
        errorContent,
        setErrorContent,
        dataEdit,
        setListMajor,
        setCheckedList,
        setHighLever,
        tagsVi,
        tagsEn,
        tagsCn,
        setTagsVi,
        setTagsEn,
        setTagsCn,
    } = props;

    const [valueList, setValueList] = useState<any[]>([]);
    const [checkedSemester, setCheckedSemester] = useState<any[]>([]);
    const [highLeverInfor, setHighLeverInfor] = useState<boolean>(true);

    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;
        thumbnailHtml.src = "" + process.env.REACT_APP_CDN + editThumbnail;
    }, [editThumbnail]);

    const { data: list_school, isLoading } = useGetListSchoolQuery();
    const { data: list_new_category } = useGetListSchoolCategoryQuery();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [selectedSchool, setSelectedSchool] = useState<string | null>(null);
    const [selectedScholarshipGroup, setSelectedScholarshipGroup] = useState<string | null>(null);
    const [selectedScholarshipType, setSelectedScholarshipType] = useState<string | null>(null);
    const [selectedTraining, setSelectedTraining] = useState<any[]>([]);

    const [getListSchoolSpecialized, { data: listSchoolSpecialized }] = useLazyGetListSchoolSpecializedQuery();
    const [getListScholarshipType, { data: listScholarshipType }] = useLazyScholarshipTypeQuery();

    const [getListScholarship, { data: listScholarship }] = useLazyGetListScholarshipBySchoolIdQuery();

    const [getListSchoolScholarshipTraining, { data: listSchoolScholarshipTraining }] =
        useLazyGetListSchoolScholarshipTrainingQuery();

    useEffect(() => {
        if (selectedSchool) {
            getListSchoolSpecialized({ schoolId: selectedSchool });
            getListScholarshipType({ schoolId: selectedSchool });
            getListSchoolScholarshipTraining({ schoolId: selectedSchool });
        }
        if (!dataEdit || dataEdit?.school?.id !== selectedSchool) {
            form.resetFields([
                "year",
                "expireApply",
                "feeApply",
                "typeOfAdmission",
                "trainings",
                "supportMode",
                "admissionPeriod",
                "trainingFormat",
                "scholarshipGroup",
                "scholarshipType",
                "majorSpecializationIds",
            ]);
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (listSchoolScholarshipTraining && listSchoolScholarshipTraining?.length > 0) {
            setSelectedTraining((prevSelected) => {
                const newSelected = [...prevSelected];

                listSchoolScholarshipTraining.forEach((item) => {
                    if (!newSelected.some((selected) => selected?.training?.id === item?.training?.id)) {
                        newSelected.push(item);
                    }
                });

                return newSelected;
            });
        }
    }, [listSchoolScholarshipTraining]);

    useEffect(() => {
        if (listSchoolSpecialized && setListMajor) {
            setListMajor(listSchoolSpecialized);
        }
    }, [listSchoolSpecialized]);

    useEffect(() => {
        if (selectedSchool && selectedScholarshipType) {
            getListScholarship({ schoolId: selectedSchool, scholarshipTypeId: selectedScholarshipType });
        }
    }, [selectedSchool, selectedScholarshipType]);

    // useEffect(() => {
    //     if (selectedScholarshipGroup && selectedScholarshipType && selectedSchool) {
    //         getListSchoolScholarshipTraining({
    //             scholarshipId: selectedScholarshipGroup,
    //             scholarshipTypeId: selectedScholarshipType,
    //             schoolId: selectedSchool,
    //         });
    //     }
    // }, [selectedScholarshipGroup, selectedScholarshipType, selectedSchool]);

    useEffect(() => {
        if (dataEdit?.schoolNewsScholarship && dataEdit.schoolNewsScholarship !== null) {
            setHighLever && setHighLever(true);
            setHighLeverInfor(true);
        } else {
            setHighLever && setHighLever(false);
            setHighLeverInfor(false);
        }
        dataEdit?.relates?.includes(RELATES.ALL)
            ? setValueList(Object.values(RELATES))
            : setValueList(dataEdit?.relates);

        const schoolId = dataEdit?.school?.id;
        let schoolNewsScholarship = null;
        if (dataEdit?.schoolNewsScholarship) {
            try {
                schoolNewsScholarship = dataEdit.schoolNewsScholarship;
            } catch (error) {
                schoolNewsScholarship = null;
            }
        }

        if (schoolNewsScholarship !== null) {
            const scholarshipType = schoolNewsScholarship?.scholarshipType;
            const scholarshipGroup = schoolNewsScholarship?.scholarshipGroup;
            if (scholarshipGroup !== null) {
                setSelectedScholarshipGroup(scholarshipGroup);
            }
            if (scholarshipType !== null) {
                setSelectedScholarshipType(scholarshipType);
            }
        }
        if (schoolId) {
            setSelectedSchool(schoolId);
        }
    }, [dataEdit]);

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const { showToast } = useToast();

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (errorContent === "titleVi" || errorContent === "titleEn" || errorContent === "titleCn") {
            setActiveKey(errorContent);
        }
        if (errorContent === "introduceVi") {
            setActiveKey("titleVi");
            form.setFields([
                {
                    name: "introduceVi",
                    errors: ["Thông tin bắt buộc"],
                },
            ]);
        }
        if (errorContent === "introduceEn") {
            setActiveKey("titleEn");
            form.setFields([
                {
                    name: "introduceEn",
                    errors: ["Field required"],
                },
            ]);
        }
        if (errorContent === "introduceCn") {
            setActiveKey("titleCn");
            form.setFields([
                {
                    name: "introduceCn",
                    errors: ["必填字段"],
                },
            ]);
        }
        setErrorContent && setErrorContent("");
    }, [errorContent]);

    const onChange = (newActiveKey: string) => {
        setInputValue("");
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    const handleKeyDownVi = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const trimmedValue = inputValue.trim();
            if (trimmedValue) {
                switch (activeKey) {
                    case "titleVi":
                        if (setTagsVi && tagsVi) {
                            const newTag = { key: tagsVi.length, value: trimmedValue };
                            setTagsVi([...tagsVi, newTag]);
                        }
                        break;
                    case "titleEn":
                        if (setTagsEn && tagsEn) {
                            const newTag = { key: tagsEn.length, value: trimmedValue };
                            setTagsEn([...tagsEn, newTag]);
                        }
                        break;
                    case "titleCn":
                        if (setTagsCn && tagsCn) {
                            const newTag = { key: tagsCn.length, value: trimmedValue };
                            setTagsCn([...tagsCn, newTag]);
                        }
                        break;
                }
                setInputValue("");
            }
        }
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <div className="row">
                    {/* col 1 */}
                    <div className="col-9">
                        <Tabs
                            type="editable-card"
                            onChange={onChange}
                            activeKey={activeKey}
                            onEdit={onEdit}
                            items={[
                                {
                                    label: "Tiếng Việt",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Tiêu đề</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Mô tả ngắn</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugVi"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "vi"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "vi");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>

                                            {/* <div>
                                                <Form.List
                                                    name="tagsVi"
                                                    initialValue={[""]}
                                                >
                                                    {(fields, { add, remove }, { errors }) => (
                                                        <>
                                                            <div className="d-flex flex-wrap gap-3">
                                                                {fields.map((field, index) => (
                                                                    <div
                                                                        key={field.key}
                                                                        className="space-medias-tab"
                                                                        style={{ width: "20%" }}
                                                                    >
                                                                        <div
                                                                            className="form-group"
                                                                            style={{
                                                                                width: "90%",
                                                                                display: "inline-block",
                                                                            }}
                                                                        >
                                                                            <div className="form-floating">
                                                                                <Form.Item
                                                                                    name={[field.name]}
                                                                                    className="form-floating"
                                                                                >
                                                                                    <Input
                                                                                        type="text"
                                                                                        className="no-icon form-floating"
                                                                                        style={{ width: "100%" }}
                                                                                    />
                                                                                </Form.Item>
                                                                                <label>Tag</label>
                                                                            </div>
                                                                        </div>
                                                                        {fields?.length > 0 ? (
                                                                            <MinusCircleOutlined
                                                                                className="dynamic-delete-button"
                                                                                style={{
                                                                                    width: "10%",
                                                                                    display: "inline-block",
                                                                                }}
                                                                                onClick={() => remove(field.name)}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{ width: "100%" }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Thêm tag
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div> */}

                                            <div className="my-3">
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            // name="tagsVi"
                                                            className="form-floating"
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="form-floating no-icon"
                                                                value={inputValue}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                onKeyDown={handleKeyDownVi}
                                                            />
                                                        </Form.Item>
                                                        <label>Tag</label>
                                                    </div>
                                                </div>
                                                {tagsVi &&
                                                    tagsVi.map((tag) => (
                                                        <Tag
                                                            key={tag.key}
                                                            color="#55acee"
                                                            className="p-1"
                                                            style={{ fontSize: 16 }}
                                                            closable
                                                            onClose={() =>
                                                                setTagsVi &&
                                                                setTagsVi(tagsVi.filter((t) => t.key !== tag.key))
                                                            }
                                                        >
                                                            {tag.value}
                                                        </Tag>
                                                    ))}
                                            </div>

                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceVi"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceVi}
                                                            onChange={(value: any) => {
                                                                setIntroduceVi(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <TinyMCE
                                                            value={introduceVi}
                                                            onChange={(value: string) => setIntroduceVi(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>Nội dung</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleVi",
                                    closable: false,
                                },
                                {
                                    label: "English",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Field required",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Title</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Field required",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Short Content</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugEn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "en"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "en");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>

                                            <div className="my-3">
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            // name="tagsVi"
                                                            className="form-floating"
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="form-floating no-icon"
                                                                value={inputValue}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                onKeyDown={handleKeyDownVi}
                                                            />
                                                        </Form.Item>
                                                        <label>Tag</label>
                                                    </div>
                                                </div>
                                                {tagsEn &&
                                                    tagsEn.map((tag) => (
                                                        <Tag
                                                            key={tag.key}
                                                            color="#55acee"
                                                            className="p-1"
                                                            style={{ fontSize: 16 }}
                                                            closable
                                                            onClose={() =>
                                                                setTagsEn &&
                                                                setTagsEn(tagsEn.filter((t) => t.key !== tag.key))
                                                            }
                                                        >
                                                            {tag.value}
                                                        </Tag>
                                                    ))}
                                            </div>

                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceEn"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceEn}
                                                            onChange={(value: any) => {
                                                                setIntroduceEn(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <TinyMCE
                                                            value={introduceEn}
                                                            onChange={(value: string) => setIntroduceEn(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>Content</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleEn",
                                    closable: false,
                                },
                                {
                                    label: "中国人",
                                    children: (
                                        <div className="body-component">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="titleCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "必填字段",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>标题</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="shortContentCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "必填字段",
                                                            },
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>简短的内容</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="slugCn"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                validator: (rule, value, callback) =>
                                                                    validateNoSpaces(rule, value, callback, "cn"),
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    validateSlug(rule, value, callback, "cn");
                                                                },
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-floating no-icon"
                                                            placeholder=""
                                                        />
                                                    </Form.Item>
                                                    <label>Slug</label>
                                                </div>
                                            </div>

                                            <div className="my-3">
                                                <div className="form-group">
                                                    <div className="form-floating">
                                                        <Form.Item
                                                            // name="tagsVi"
                                                            className="form-floating"
                                                        >
                                                            <Input
                                                                type="text"
                                                                className="form-floating no-icon"
                                                                value={inputValue}
                                                                onChange={(e) => setInputValue(e.target.value)}
                                                                onKeyDown={handleKeyDownVi}
                                                            />
                                                        </Form.Item>
                                                        <label>Tag</label>
                                                    </div>
                                                </div>
                                                {tagsCn &&
                                                    tagsCn.map((tag) => (
                                                        <Tag
                                                            key={tag.key}
                                                            color="#55acee"
                                                            className="p-1"
                                                            style={{ fontSize: 16 }}
                                                            closable
                                                            onClose={() =>
                                                                setTagsCn &&
                                                                setTagsCn(tagsCn.filter((t) => t.key !== tag.key))
                                                            }
                                                        >
                                                            {tag.value}
                                                        </Tag>
                                                    ))}
                                            </div>

                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name="introduceCn"
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                            className="text-editor"
                                                            value={introduceCn}
                                                            onChange={(value: any) => {
                                                                setIntroduceCn(value);
                                                            }}
                                                            formats={formats}
                                                            modules={modules}
                                                        /> */}
                                                        <TinyMCE
                                                            value={introduceCn}
                                                            onChange={(value: string) => setIntroduceCn(value)}
                                                        />
                                                    </Form.Item>
                                                    <label>内容</label>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                    key: "titleCn",
                                    closable: false,
                                },
                            ]}
                        />
                        <div className="my-3">
                            <h6>Chọn nơi hiển thị bài viết</h6>
                            <div className="mt-3">
                                <CheckboxGroup
                                    options={RELATES_OPTIONS}
                                    value={valueList}
                                    onChange={(e) => {
                                        if (
                                            valueList &&
                                            valueList.length > e.length &&
                                            valueList.includes(RELATES.ALL)
                                        ) {
                                            if (e.length === 4 && !e.includes(RELATES.ALL)) {
                                                setCheckedList && setCheckedList("");
                                                setValueList([]);
                                            } else {
                                                const value = e.filter((item) => item !== RELATES.ALL);
                                                setCheckedList && setCheckedList(value);
                                                setValueList(value);
                                            }
                                        } else {
                                            if (e.includes(RELATES.ALL)) {
                                                const allValues = RELATES_OPTIONS.map((option) => option.value);
                                                setCheckedList && setCheckedList([RELATES.ALL]);
                                                setValueList(allValues);
                                            } else {
                                                setCheckedList && setCheckedList(e);
                                                setValueList(e);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="form-floating">
                                <Form.Item
                                    name="schoolId"
                                    className="form-floating"
                                >
                                    <Select
                                        loading={isLoading}
                                        className="form-control form-floating"
                                        bordered={false}
                                        showSearch
                                        allowClear
                                        onChange={(value) => {
                                            setSelectedSchool(value);
                                        }}
                                        filterOption={(input, option) =>
                                            (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {list_school &&
                                            list_school.map((o: any, i: any) => {
                                                return (
                                                    <Select.Option
                                                        key={o.id}
                                                        value={o.id}
                                                    >
                                                        {o.language[0].name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Form.Item>
                                <label>Trường</label>
                            </div>
                        </div>
                        {selectedSchool && (
                            <div className="my-3">
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <h6>Thông tin nâng cao</h6>
                                    </Col>
                                    <Col span={12}>
                                        <Checkbox
                                            checked={highLeverInfor}
                                            onChange={() => {
                                                setHighLever && setHighLever(!highLeverInfor);
                                                setHighLeverInfor(!highLeverInfor);
                                            }}
                                        >
                                            Thông tin tuyển sinh
                                        </Checkbox>
                                    </Col>
                                </Row>
                                {highLeverInfor && (
                                    <>
                                        <div className="my-3">
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="year"
                                                                className="form-floating"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Thông tin bắt buộc",
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    onWheel={(e) => e.currentTarget.blur()}
                                                                    size="large"
                                                                    style={{ width: "100%" }}
                                                                    min={1}
                                                                    className="form-floating no-icon"
                                                                />
                                                            </Form.Item>
                                                            <label>Mùa nhập học</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="expireApply"
                                                                className="form-floating"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Thông tin bắt buộc",
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker
                                                                    size="large"
                                                                    style={{ width: "100%" }}
                                                                />
                                                            </Form.Item>
                                                            <label>Hạn Apply</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="feeApply"
                                                                className="form-floating"
                                                            >
                                                                <InputNumber
                                                                    onWheel={(e) => e.currentTarget.blur()}
                                                                    size="large"
                                                                    style={{ width: "100%" }}
                                                                    className="form-floating no-icon"
                                                                />
                                                            </Form.Item>
                                                            <label>Phí Apply</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="typeOfAdmission"
                                                                className="form-floating"
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    placeholder=""
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {TYPE_OF_ADMISSION_OPTIONS.map((o: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={o.value}
                                                                                value={o.value}
                                                                            >
                                                                                {o.label}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Loại tuyển sinh</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="supportMode"
                                                                className="form-floating"
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    placeholder=""
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {SUPPORT_MODE_OPTIONS.map((o: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={o.value}
                                                                                value={o.value}
                                                                            >
                                                                                {o.label}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Chế độ</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="trainingFormat"
                                                                className="form-floating"
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    mode="multiple"
                                                                >
                                                                    {TRAINING_FORMAT.map((o: any) => {
                                                                        return (
                                                                            <Select.Option
                                                                                key={o}
                                                                                value={o.value}
                                                                            >
                                                                                {o.value}
                                                                            </Select.Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Hình thức đào tạo</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row gutter={20}>
                                                <Col span={12}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="scholarshipType"
                                                                className="form-floating"
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    placeholder=""
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(value) => {
                                                                        setSelectedScholarshipType(value);
                                                                    }}
                                                                >
                                                                    {listScholarshipType &&
                                                                        listScholarshipType.map((item: any) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={item.id}
                                                                                    value={item.id}
                                                                                >
                                                                                    {item.language[0].title}
                                                                                </Select.Option>
                                                                            );
                                                                        })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Loại học bổng</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={12}>
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name="scholarshipGroup"
                                                                className="form-floating"
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    placeholder=""
                                                                    showSearch
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                    onChange={(value) =>
                                                                        setSelectedScholarshipGroup(value)
                                                                    }
                                                                >
                                                                    {listScholarship &&
                                                                        listScholarship.map((item: any) => {
                                                                            return (
                                                                                <Select.Option
                                                                                    key={item.scholarshipId}
                                                                                    value={item.scholarshipId}
                                                                                >
                                                                                    {item.scholarship.language[0].title}
                                                                                </Select.Option>
                                                                            );
                                                                        })}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>Nhóm học bổng</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="my-3">
                                            <div className="form-group">
                                                <div className="form-floating">
                                                    <h6>Kỳ nhập học</h6>

                                                    <Form.Item
                                                        name="admissionPeriod"
                                                        className="form-floating"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Thông tin bắt buộc",
                                                            },
                                                        ]}
                                                    >
                                                        <CheckboxGroup
                                                            options={ADMISSION_PERIOD_OPTIONS}
                                                            value={checkedSemester}
                                                            onChange={(e) => setCheckedSemester(e)}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <h6>Hệ đào tạo</h6>
                                            <div className="">
                                                <Form.List
                                                    name="trainings"
                                                    initialValue={[{}]}
                                                >
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, fieldKey, ...restField }) => (
                                                                <Card
                                                                    title={`Hệ đào tạo ${name + 1}`}
                                                                    extra={
                                                                        name !== 0 && (
                                                                            <CloseOutlined
                                                                                onClick={() => {
                                                                                    remove(name);
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    key={key}
                                                                >
                                                                    <div className="form-group">
                                                                        <div className="form-floating">
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "trainingId"]}
                                                                                className="form-floating"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Thông tin bắt buộc",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    className="form-control form-floating"
                                                                                    bordered={false}
                                                                                    placeholder=""
                                                                                    showSearch
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.children as any)
                                                                                            ?.toLowerCase()
                                                                                            .indexOf(
                                                                                                input.toLowerCase()
                                                                                            ) >= 0
                                                                                    }
                                                                                >
                                                                                    {selectedTraining &&
                                                                                        selectedTraining.map(
                                                                                            (item: any) => {
                                                                                                return (
                                                                                                    <Select.Option
                                                                                                        key={
                                                                                                            item
                                                                                                                .training
                                                                                                                .id
                                                                                                        }
                                                                                                        value={
                                                                                                            item
                                                                                                                .training
                                                                                                                .id
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            item
                                                                                                                .training
                                                                                                                .language[0]
                                                                                                                .title
                                                                                                        }
                                                                                                    </Select.Option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                </Select>
                                                                            </Form.Item>
                                                                            <label>Hệ đào tạo</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className="form-floating">
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[name, "programs"]}
                                                                                className="form-floating"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Thông tin bắt buộc",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    className="form-control form-floating"
                                                                                    bordered={false}
                                                                                    showSearch
                                                                                    filterOption={(input, option) =>
                                                                                        (option?.children as any)
                                                                                            ?.toLowerCase()
                                                                                            .indexOf(
                                                                                                input.toLowerCase()
                                                                                            ) >= 0
                                                                                    }
                                                                                    mode="multiple"
                                                                                >
                                                                                    {PROGRAMS_LANGUAGE.map((o) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                key={o.value}
                                                                                                value={o.value}
                                                                                            >
                                                                                                {o.label}
                                                                                            </Select.Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>
                                                                            </Form.Item>
                                                                            <label>Chương trình đào tạo</label>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            ))}
                                                            <Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    Thêm thông tin hệ đào tạo
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name="majorSpecializationIds"
                                                    className="form-floating"
                                                >
                                                    <Select
                                                        className="form-control form-floating"
                                                        bordered={false}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            (option?.children as any)
                                                                ?.toLowerCase()
                                                                .indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        mode="multiple"
                                                    >
                                                        {listSchoolSpecialized &&
                                                            listSchoolSpecialized.map((item: any) => {
                                                                return (
                                                                    <Select.Option
                                                                        key={item.specialized.id}
                                                                        value={item.specialized.id}
                                                                    >
                                                                        {item.specialized.language[0].title}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                    </Select>
                                                </Form.Item>
                                                <label>Chuyên ngành</label>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-3">
                        <div className="body-component">
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="categoryId"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            allowClear
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_new_category &&
                                                list_new_category.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Danh mục tin tức</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="status"
                                        className="form-floating"
                                        initialValue={1}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                        >
                                            <Select.Option value={0}>INACTIVE</Select.Option>
                                            <Select.Option value={1}>ACTIVE</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <label>Trạng thái</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="frame-upload">
                                    <img
                                        className="img-fluid"
                                        src={blankImage}
                                        alt=""
                                        id="thumbnail"
                                    />
                                </div>
                                <Upload.Dragger {...uploadProps}>
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">
                                            <i className="bi-upload"></i>
                                            Tải ảnh thumbnail
                                        </button>
                                    </div>
                                </Upload.Dragger>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const MemoizedGeneralTab = React.memo(GeneralTab);

export default MemoizedGeneralTab;
