import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListAreaQuery } from "@redux/queries/admin/admin.area";
import { useCreateCityMutation, useUpdateCityMutation } from "@redux/queries/admin/admin.city";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { identity, pickBy } from "lodash";
import { useEffect, useState } from "react";
import Tab from "./Tab";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createCity, isLoadingCreate] = useCreateCityMutation();
    const [updateCity, isLoadingUpdate] = useUpdateCityMutation();
    const { showToast } = useToast();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");
    const [errorContent, setErrorContent] = useState<string>();

    const { data: list_area, isLoading: isLoadingArea, error: errorArea } = useGetListAreaQuery();

    useEffect(() => {
        if (dataEdit) {
            const areaId = dataEdit?.area?.id;
            const introduceVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.content;
            const introduceEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.content;
            const introduceCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.content;
            const slugVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug;
            const slugEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.slug;
            const slugCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug;
            const cityScaleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.cityScale;
            const cityScaleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.cityScale;
            const cityScaleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.cityScale;
            const cityClassificationVi = dataEdit?.language?.find((item: any) => item.lang === "vi")
                ?.cityClassification;
            const cityClassificationEn = dataEdit?.language?.find((item: any) => item.lang === "en")
                ?.cityClassification;
            const cityClassificationCn = dataEdit?.language?.find((item: any) => item.lang === "cn")
                ?.cityClassification;
            const administrativeLevelVi = dataEdit?.language?.find((item: any) => item.lang === "vi")
                ?.administrativeLevel;
            const administrativeLevelEn = dataEdit?.language?.find((item: any) => item.lang === "en")
                ?.administrativeLevel;
            const administrativeLevelCn = dataEdit?.language?.find((item: any) => item.lang === "cn")
                ?.administrativeLevel;
            const shortContentVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent;
            const shortContentEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent;
            const shortContentCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent;
            setIntroduceVi(introduceVi || "");
            setIntroduceEn(introduceEn || "");
            setIntroduceCn(introduceCn || "");
            form.setFieldsValue({
                ...dataEdit,
                titleVi: dataEdit?.language[0].title,
                titleEn: dataEdit?.language[1].title,
                titleCn: dataEdit?.language[2].title,
                shortContentVi,
                shortContentEn,
                shortContentCn,
                majorId: dataEdit?.major?.id,
                introduceVi,
                introduceEn,
                introduceCn,
                slugVi,
                slugEn,
                slugCn,
                cityScaleVi,
                cityScaleEn,
                cityScaleCn,
                cityClassificationVi,
                cityClassificationEn,
                cityClassificationCn,
                administrativeLevelVi,
                administrativeLevelEn,
                administrativeLevelCn,
                trainingId: dataEdit?.training?.id,
                areaId: areaId,
            });
            setEditThumbnail(dataEdit?.area?.thumbnail);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        let value;
        try {
            value = await form.validateFields();
            if (!introduceVi) {
                setErrorContent("introduceVi");
                return;
            }
            if (!introduceEn) {
                setErrorContent("introduceEn");
                return;
            }
            if (!introduceCn) {
                setErrorContent("introduceCn");
                return;
            }
        } catch (errorInfo) {
            const fieldError = (errorInfo as any)?.errorFields[0].name[0];
            if (fieldError === "titleVi" || fieldError === "titleEn" || fieldError === "titleCn") {
                setErrorContent(fieldError);
            }
            return;
        }
        const language = [
            {
                lang: "vi",
                title: values.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,
                content: values.introduceVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.content,
                shortContent:
                    values.shortContentVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.shortContent,
                slug: value.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
                cityScale: value.cityScaleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.cityScale,
                cityClassification:
                    value.cityClassificationVi ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.cityClassification,
                administrativeLevel:
                    value.administrativeLevelVi ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.administrativeLevel,
            },
            {
                lang: "en",
                title: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                content: values.introduceEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.content,
                shortContent:
                    values.shortContentEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.shortContent,
                slug: value.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
                cityScale: value.cityScaleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.cityScale,
                cityClassification:
                    value.cityClassificationEn ||
                    dataEdit?.language?.find((item: any) => item.lang === "en")?.cityClassification,
                administrativeLevel:
                    value.administrativeLevelEn ||
                    dataEdit?.language?.find((item: any) => item.lang === "en")?.administrativeLevel,
            },
            {
                lang: "cn",
                title: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                content: values.introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,
                shortContent:
                    values.shortContentCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.shortContent,
                slug: value.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
                cityScale: value.cityScaleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.cityScale,
                cityClassification:
                    value.cityClassificationCn ||
                    dataEdit?.language?.find((item: any) => item.lang === "cn")?.cityClassification,
                administrativeLevel:
                    value.administrativeLevelCn ||
                    dataEdit?.language?.find((item: any) => item.lang === "cn")?.administrativeLevel,
            },
        ];

        const data = pickBy(
            {
                ...values,
                language,
                thumbnail: thumbnail || editThumbnail,
                level: Number(values.level),
            },
            identity
        ) as any;
        const result = dataEdit
            ? await updateCity({
                  id: dataEdit.id,
                  ...data,
              })
            : await createCity(data);
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setEditThumbnail(null);
            setThumbnail(null);
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update City" : "Add City"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Tab
                                form={form}
                                thumbnail={thumbnail}
                                setThumbnail={(value: any) => setThumbnail(value)}
                                introduceVi={introduceVi}
                                introduceEn={introduceEn}
                                introduceCn={introduceCn}
                                editThumbnail={editThumbnail}
                                setIntroduceVi={(value: any) => setIntroduceVi(value)}
                                setIntroduceEn={(value: any) => setIntroduceEn(value)}
                                setIntroduceCn={(value: any) => setIntroduceCn(value)}
                                errorContent={errorContent}
                                setErrorContent={(value: any) => setErrorContent(value)}
                            />
                        </div>
                    </div>
                    <Row gutter={20}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="level"
                                        className="form-floating"
                                        rules={[
                                            {
                                                pattern: /^[0-9]*$/,
                                                message: "Phải nhập số",
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="text"
                                            className="form-floating no-icon"
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <label>Level</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="areaId"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {list_area &&
                                                list_area.map((o: any, i: any) => {
                                                    return (
                                                        <Select.Option
                                                            key={o.id}
                                                            value={o.id}
                                                        >
                                                            {o.language && o.language[0].title}
                                                        </Select.Option>
                                                    );
                                                })}
                                        </Select>
                                    </Form.Item>
                                    <label>Khu vực</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
