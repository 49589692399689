import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import _ from "lodash";
import { AnyAction } from "@reduxjs/toolkit";

const BASE = "/api/consultation";

export const adminConsultationApi = createApi({
    reducerPath: "adminConsultationApi",
    keepUnusedDataFor: 30,
    tagTypes: ["Consultation"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListConsultation: builder.query<Array<AnyAction>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: "Consultation" as const, id })),
                              { type: "Consultation", id: "Consultation-LIST" },
                          ]
                        : [{ type: "Consultation", id: "Consultation-LIST" }],
            }),
            getListConsultationPaginate: builder.query<
                IResponseDataAdmin<AnyAction>,
                { page: number; limit: number; name?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: query,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Consultation" as const, id })),
                              { type: "Consultation", id: "Consultation-LIST" },
                          ]
                        : [{ type: "Consultation", id: "Consultation-LIST" }],
            }),
            createConsultation: builder.mutation<any, AnyAction>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "POST",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Consultation"],
            }),
            updateConsultation: builder.mutation<any, AnyAction>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: _.omit(params, ["id"])
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Consultation"],
            }),
            deleteConsultation: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Consultation"],
            }),
            deleteMultiConsultation: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Consultation"],
            }),
            processedConsultation : builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/processed/${params.id}`,
                    method: "PATCH",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Consultation"],
            }),
        };
    },
});

export const {
    useGetListConsultationQuery,
    useGetListConsultationPaginateQuery,
    useLazyGetListConsultationPaginateQuery,
    useCreateConsultationMutation,
    useUpdateConsultationMutation,
    useDeleteConsultationMutation,
    useDeleteMultiConsultationMutation,
    useProcessedConsultationMutation,
} = adminConsultationApi;
