import { CloseOutlined } from "@ant-design/icons";
import TinyMCE from "@components/Editor";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateBlogMutation, useUpdateBlogMutation } from "@redux/queries/admin/admin.approve.bog";
import { useGetListUserQuery } from "@redux/queries/admin/admin.user";
import { useGetListBlogCategoryQuery } from "@redux/queries/user/categories.blog";
import { Button, Card, Col, Form, Input, Modal, Row, Select } from "antd";
import { useEffect, useState } from "react";
import UploadImg from "./UploadImg";
import { editorConfig } from "@utils/editConfig";
import JoditEditor from "jodit-react";

interface Props {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const HandleForm = (props: Props) => {
    const { openHandleForm, setOpenHandleForm, dataEdit, setDataEdit } = props;
    const [form] = Form.useForm();

    const [thumbnail, setThumbnail] = useState<any>(null);
    const [editThumbnail, setEditThumbnail] = useState<any>(null);
    const [content, setContent] = useState<any>("");
    const [shortContent, setShortContent] = useState<any>("");

    const [createBlog] = useCreateBlogMutation();
    const [updateBlog] = useUpdateBlogMutation();
    const { data: listUsers } = useGetListUserQuery();
    const { data: listCategories } = useGetListBlogCategoryQuery();

    const { showToast } = useToast();

    const onFinish = async (values: any) => {
        values.tags = values.tags && values.tags.map((item: any) => item.tag);
        const result = dataEdit
            ? await updateBlog({
                  id: dataEdit.id,
                  ...values,
                  thumbnail: thumbnail || editThumbnail,
                  content: content,
                  shortContent: shortContent,
              })
            : await createBlog({
                  ...values,
                  thumbnail: thumbnail || editThumbnail,
                  content: content,
                  shortContent: shortContent,
              });

        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    useEffect(() => {
        if (dataEdit) {
            const tags = dataEdit?.tags && dataEdit.tags.map((item: any) => ({ tag: item }));
            form.setFieldsValue({
                ...dataEdit,
                tags: tags,
            });
            setContent(dataEdit.content);
            setShortContent(dataEdit.shortContent);
            setEditThumbnail(dataEdit.thumbnail);
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ tags: [{}] }}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                    setEditThumbnail(null);
                    setThumbnail(null);
                }}
                title={dataEdit ? "Update Blog" : "Add Blog"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={1000}
            >
                <div className="body-component">
                    <Row gutter={24}>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="userId"
                                        className="form-floating"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Thông tin bắt buộc",
                                            },
                                        ]}
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {listUsers &&
                                                listUsers.map((user) => (
                                                    <Select.Option
                                                        key={user.id}
                                                        value={user.id}
                                                    >
                                                        {user.firstName + " " + user.lastName}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <label>Người đăng bài</label>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className="form-group">
                                <div className="form-floating">
                                    <Form.Item
                                        name="categoryId"
                                        className="form-floating"
                                    >
                                        <Select
                                            className="form-control form-floating"
                                            bordered={false}
                                            placeholder=""
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >=
                                                0
                                            }
                                        >
                                            {listCategories &&
                                                listCategories.map((category) => (
                                                    <Select.Option
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        {category.language[0].title}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <label>Thể loại</label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="title"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="no-icon form-floating"
                                />
                            </Form.Item>
                            <label>Tiêu đề</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="shortContent"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                {/* <TinyMCE
                                    value={shortContent}
                                    onChange={(value: string) => setShortContent(value)}
                                    aria-label="Short content editor"
                                /> */}
                                <TinyMCE
                                    value={shortContent}
                                    onChange={(value) => setShortContent(value)}
                                    aria-label="Short content editor"
                                />
                            </Form.Item>
                            <label>Nội dung tóm tắt</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                className="form-floating"
                                name="content"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <TinyMCE
                                    value={content}
                                    onChange={(value: string) => setContent(value)}
                                />
                            </Form.Item>
                            <label>Nội dung</label>
                        </div>
                    </div>

                    <UploadImg
                        setThumbnail={(thumbnail) => setThumbnail(thumbnail)}
                        editThumbnail={editThumbnail}
                    />
                    <Form.List
                        name="tags"
                        initialValue={[""]}
                    >
                        {(fields, { add, remove }) => (
                            <div>
                                {fields.map((field) => (
                                    <Card
                                        size="small"
                                        title={`Tag ${field.name + 1}`}
                                        key={field.key}
                                        extra={
                                            field.name !== 0 && (
                                                <CloseOutlined
                                                    onClick={() => {
                                                        remove(field.name);
                                                    }}
                                                />
                                            )
                                        }
                                    >
                                        <div className="form-group">
                                            <div className="form-floating">
                                                <Form.Item
                                                    name={[field.name, "tag"]}
                                                    className="form-floating"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Thông tin bắt buộc",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="text"
                                                        className="no-icon form-floating"
                                                    />
                                                </Form.Item>
                                                <label>Tag</label>
                                            </div>
                                        </div>
                                    </Card>
                                ))}

                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    + Thêm Tag
                                </Button>
                            </div>
                        )}
                    </Form.List>
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
