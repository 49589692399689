import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";

const BASE = "/api/school/type";

export const adminSchoolTypeApi = createApi({
    reducerPath: "adminSchoolTypeApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["SchoolType"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListSchoolType: builder.query<Array<IAdminSchoolType>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListSchoolTypePaginate: builder.query<
                IResponseDataAdmin<IAdminSchoolType>,
                { page: number; limit: number; title?: string }
            >({
                query: (query) => ({
                    url: `${BASE}/paginate`,
                    method: "GET",
                    params: {
                        page: query.page,
                        limit: query.limit,
                        title: query.title,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "SchoolType" as const, id })),
                              { type: "SchoolType", id: "SchoolType-LIST" },
                          ]
                        : [{ type: "SchoolType", id: "SchoolType-LIST" }],
            }),
            createSchoolType: builder.mutation<any, IAdminSchoolType>({
                query: (body) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolType"],
            }),
            updateSchoolType: builder.mutation<any, IAdminSchoolType>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: params,
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolType"],
            }),
            deleteSchoolType: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["SchoolType"],
            }),
        };
    },
});

export const {
    useGetListSchoolTypeQuery,
    useGetListSchoolTypePaginateQuery,
    useCreateSchoolTypeMutation,
    useDeleteSchoolTypeMutation,
    useUpdateSchoolTypeMutation,
} = adminSchoolTypeApi;
