import TabComponent from "@components/Tab";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useCreateSchoolTypeMutation, useUpdateSchoolTypeMutation } from "@redux/queries/admin/admin.school.type";
import { Form, Modal } from "antd";
import { identity, pickBy } from "lodash";
import React, { useEffect } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const TextVi = [
    {
        name: "slugVi",
        require: true,
        title: "Slug",
    },
];

const TextEn = [
    {
        name: "slugEn",
        require: true,
        title: "Slug",
    },
];

const TextCn = [
    {
        name: "slugCn",
        require: true,
        title: "Slug",
    },
];

const HandleForm = (props: HandleFormProps) => {
    const { openHandleForm, setOpenHandleForm, dataEdit, setDataEdit } = props;

    const [createSchoolType] = useCreateSchoolTypeMutation();
    const [updateSchoolType] = useUpdateSchoolTypeMutation();

    const [form] = Form.useForm();

    const { showToast } = useToast();

    const onFinish = async (values: any) => {
        const language = [
            {
                lang: "vi",
                title: values.titleVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.title,

                slug: values.slugVi || dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug,
            },
            {
                lang: "en",
                title: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,

                slug: values.slugEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.slug,
            },
            {
                lang: "cn",
                title: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                content: values.introduceCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.content,

                slug: values.slugCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug,
            },
        ];

        const data = pickBy(
            {
                ...values,
                language,
            },
            identity
        ) as any;

        console.log("data: ", data);

        const results = dataEdit
            ? await updateSchoolType({
                  id: dataEdit.id,
                  ...data,
              })
            : await createSchoolType(data);

        if ("error" in results) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in results) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    useEffect(() => {
        if (dataEdit) {
            const slugVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.slug;
            const slugEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.slug;
            const slugCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.slug;
            const titleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.title;
            const titleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.title;
            const titleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.title;
            form.setFieldsValue({
                ...dataEdit,
                slugVi,
                slugEn,
                slugCn,
                titleVi,
                titleEn,
                titleCn,
            });
        }
    }, [dataEdit]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update School Type" : "Add School Type"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={500}
            >
                <div className="body-component">
                    <TabComponent
                        nameTextVi={TextVi}
                        nameTextEn={TextEn}
                        nameTextCn={TextCn}
                        titleVi="titleVi"
                        titleEn="titleEn"
                        titleCn="titleCn"
                        form={form}
                    />
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
