import { createApi } from "@reduxjs/toolkit/query/react";
import { customFetchBase } from "../customFetchBase";
import queryString from "query-string";

const BASE = "/api/scholarship";

export const adminScholarshipApi = createApi({
    reducerPath: "adminScholarshipApi",
    keepUnusedDataFor: 30 * 60,
    tagTypes: ["Scholarship"],
    baseQuery: customFetchBase,
    endpoints(builder) {
        return {
            getListScholarship: builder.query<Array<IScholarship>, void>({
                query: (query) => ({
                    url: `${BASE}/`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getScholarshipById: builder.query<any, { id: string }>({
                query: (param) => ({
                    url: `${BASE}/${param.id}`,
                    method: "GET",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
            }),
            getListScholarshipPaginate: builder.query<
                IResponseDataAdmin<IScholarship>,
                { page: number; limit: number; title?: string; trainingIds?: Array<string>; policyIds?: Array<string> }
            >({
                query: (query) => {
                    const queryObject: any = {
                        url: `${BASE}/paginate?` + queryString.stringify(query, { arrayFormat: "bracket" }),
                        method: "GET",
                    };
                    return queryObject;
                },
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                providesTags: (result, error, page) =>
                    result
                        ? [
                              ...result.items.map(({ id }) => ({ type: "Scholarship" as const, id })),
                              { type: "Scholarship", id: "Scholarship-LIST" },
                          ]
                        : [{ type: "Scholarship", id: "Scholarship-LIST" }],
            }),
            createScholarship: builder.mutation<any, IAdminScholarshipInput>({
                query: (params) => ({
                    url: `${BASE}/`,
                    method: "POST",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Scholarship"],
            }),
            updateScholarship: builder.mutation<any, IAdminScholarshipInput>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "PATCH",
                    body: {
                        ...params,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Scholarship"],
            }),
            deleteScholarship: builder.mutation<any, { id: string }>({
                query: (params) => ({
                    url: `${BASE}/${params.id}`,
                    method: "DELETE",
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Scholarship"],
            }),
            deleteMultiScholarship: builder.mutation<any, { ids: Array<string> }>({
                query: (params) => ({
                    url: `${BASE}`,
                    method: "DELETE",
                    body: {
                        ids: params.ids,
                    },
                }),
                transformErrorResponse(response, meta, arg) {
                    return response.data;
                },
                invalidatesTags: ["Scholarship"],
            }),
        };
    },
});

export const {
    useGetListScholarshipQuery,
    useLazyGetScholarshipByIdQuery,
    useGetListScholarshipPaginateQuery,
    useLazyGetListScholarshipPaginateQuery,
    useCreateScholarshipMutation,
    useUpdateScholarshipMutation,
    useDeleteScholarshipMutation,
    useDeleteMultiScholarshipMutation,
} = adminScholarshipApi;
