import blankImage from "@assets/images/blank.png";
import TinyMCE from "@components/Editor";
import TabComponent from "@components/Tab";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_DELETE_SUCCESS, TOAST_UPLOAD_ERROR, TOAST_UPLOAD_SUCCESS } from "@consts";
import store from "@redux/store";
import { validateNoSpaces } from "@utils/common";
import { editorConfig } from "@utils/editConfig";
import { Col, Form, Input, Row, Tabs, UploadFile, message } from "antd";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import "jodit";
import React, { useEffect, useRef, useState } from "react";
import {
    SELECT_ADMINISTRATIVE_LEVEL,
    SELECT_CITY_CLASSIFICATION,
    SELECT_CITY_SIZE,
    TITLE_ADMINISTRATIVE_LEVEL,
    TITLE_CITY_CLASSIFICATION,
    TITLE_CITY_SIZE,
} from "./TypeSelect";

interface IProps {
    introduceVi: string;
    introduceEn: string;
    introduceCn: string;

    setIntroduceVi: (value: string) => void;
    setIntroduceEn: (value: string) => void;
    setIntroduceCn: (value: string) => void;

    thumbnail?: string;
    setThumbnail?: (value: string) => void;
    editThumbnail?: string;

    form: any;
    errorContent?: string;
    setErrorContent?: (value: string) => void;
}

const SelectVi = [
    {
        name: "cityScaleVi",
        require: true,
        title: TITLE_CITY_SIZE.VI,
        data: SELECT_CITY_SIZE,
        multi: false,
    },
    {
        name: "cityClassificationVi",
        require: true,
        title: TITLE_CITY_CLASSIFICATION.VI,
        data: SELECT_CITY_CLASSIFICATION,
        multi: false,
    },
    {
        name: "administrativeLevelVi",
        require: true,
        title: TITLE_ADMINISTRATIVE_LEVEL.VI,
        data: SELECT_ADMINISTRATIVE_LEVEL,
        multi: false,
    },
];

const SelectEn = [
    {
        name: "cityScaleEn",
        require: true,
        title: TITLE_CITY_SIZE.EN,
        data: SELECT_CITY_SIZE,
        multi: false,
    },
    {
        name: "cityClassificationEn",
        require: true,
        title: TITLE_CITY_CLASSIFICATION.EN,
        data: SELECT_CITY_CLASSIFICATION,
        multi: false,
    },
    {
        name: "administrativeLevelEn",
        require: true,
        title: TITLE_ADMINISTRATIVE_LEVEL.EN,
        data: SELECT_ADMINISTRATIVE_LEVEL,
        multi: false,
    },
];
const SelectCn = [
    {
        name: "cityScaleCn",
        require: true,
        title: TITLE_CITY_SIZE.CN,
        data: SELECT_CITY_SIZE,
        multi: false,
    },
    {
        name: "cityClassificationCn",
        require: true,
        title: TITLE_CITY_CLASSIFICATION.CN,
        data: SELECT_CITY_CLASSIFICATION,
        multi: false,
    },
    {
        name: "administrativeLevelCn",
        require: true,
        title: TITLE_ADMINISTRATIVE_LEVEL.CN,
        data: SELECT_ADMINISTRATIVE_LEVEL,
        multi: false,
    },
];

const TextVi = [
    {
        name: "slugVi",
        require: true,
        title: "Slug",
    },
    {
        name: "shortContentVi",
        require: true,
        title: "Mô tả ngắn",
    },
];

const TextEn = [
    {
        name: "slugEn",
        require: true,
        title: "Slug",
    },
    {
        name: "shortContentEn",
        require: true,
        title: "Short Content",
    },
];

const TextCn = [
    {
        name: "slugCn",
        require: true,
        title: "Slug",
    },
    {
        name: "shortContentCn",
        require: true,
        title: "简短的内容",
    },
];

const Tab: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        introduceVi,
        introduceEn,
        introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        form,
        thumbnail,
        setThumbnail,
        editThumbnail,
        errorContent,
        setErrorContent,
    } = props;

    const ContentVi = [
        {
            name: "introduceVi",
            title: "Nội dung",
            data: introduceVi,
            require: true,
        },
    ];

    const ContentEn = [
        {
            name: "introduceEn",
            title: "Content",
            data: introduceEn,
            require: true,
        },
    ];

    const ContentCn = [
        {
            name: "introduceCn",
            title: "内容",
            data: introduceCn,
            require: true,
        },
    ];

    useEffect(() => {
        const thumbnailHtml = document.getElementById("thumbnail") as HTMLImageElement;
        thumbnailHtml.src = "" + process.env.REACT_APP_CDN + editThumbnail;
    }, [editThumbnail]);

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const token = store.getState().auth.accessToken;
    const type = store.getState().auth.type;
    const { showToast } = useToast();

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    useEffect(() => {
        if (errorContent === "titleVi" || errorContent === "titleEn" || errorContent === "titleCn") {
            setActiveKey(errorContent);
        }
        if (errorContent === "introduceVi") {
            setActiveKey("titleVi");
            form.setFields([
                {
                    name: "introduceVi",
                    errors: ["Thông tin bắt buộc"],
                },
            ]);
        }
        if (errorContent === "introduceEn") {
            setActiveKey("titleEn");
            form.setFields([
                {
                    name: "introduceEn",
                    errors: ["Field required"],
                },
            ]);
        }
        if (errorContent === "introduceCn") {
            setActiveKey("titleCn");
            form.setFields([
                {
                    name: "introduceCn",
                    errors: ["必填字段"],
                },
            ]);
        }
        setErrorContent && setErrorContent("");
    }, [errorContent]);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const newTabIndex = useRef(0);

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    const uploadProps: any = {
        name: "files",
        action: `${process.env.REACT_APP_HOST}/api/school/images`,
        method: "POST",
        maxCount: 1,
        accept: "image/*",
        headers: {
            Authorization: `${type} ${token}`,
        },
        fileList,
        showUploadList: false,
        beforeUpload: (file: RcFile) => {
            const isJpgOrPngOrPdf = file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPngOrPdf) {
                message.error("Không đúng định dạng file yêu cầu");
            }
            const thumbnail = document.getElementById("thumbnail") as HTMLImageElement;
            thumbnail.src = URL.createObjectURL(file);
            return isJpgOrPngOrPdf;
        },
        onChange: async (info: UploadChangeParam) => {
            setFileList(info.fileList);
            if (info.file.response && info.file.response && info.file.status === "done") {
                showToast({ ...TOAST_UPLOAD_SUCCESS });
                setFileList([info.file]);
                setThumbnail && setThumbnail(info.file.response && info.file.response[0].url);
            }
            if (info.file.status === "removed") {
                showToast({ ...TOAST_DELETE_SUCCESS });
                setFileList([]);
            }
            if (info.file.status === "error") {
                showToast({ ...TOAST_UPLOAD_ERROR });
            }
        },
    };

    return (
        <>
            <div
                className="tab-pane fade show active"
                id="default-tab-pane"
                role="tabpanel"
                aria-labelledby="default-tab"
                tabIndex={0}
            >
                <TabComponent
                    setIntroduceVi={(e) => setIntroduceVi(e)}
                    setIntroduceEn={(e) => setIntroduceEn(e)}
                    setIntroduceCn={(e) => setIntroduceCn(e)}
                    nameContentVi={ContentVi}
                    nameContentEn={ContentEn}
                    nameContentCn={ContentCn}
                    nameSelectVi={SelectVi}
                    nameSelectEn={SelectEn}
                    nameSelectCn={SelectCn}
                    nameTextVi={TextVi}
                    nameTextEn={TextEn}
                    nameTextCn={TextCn}
                    titleVi="titleVi"
                    titleEn="titleEn"
                    titleCn="titleCn"
                    form={form}
                />

                <div className="body-component">
                    <div className="form-group">
                        <div className="frame-upload">
                            <img
                                className="img-fluid"
                                src={blankImage}
                                alt=""
                                id="thumbnail"
                            />
                        </div>
                        <Upload.Dragger {...uploadProps}>
                            <div className="upload-btn-wrapper">
                                <button className="btn">Tải ảnh thumbnail</button>
                            </div>
                        </Upload.Dragger>
                    </div>
                </div>
            </div>
        </>
    );
};

const MemoizedTab = React.memo(Tab);

export default MemoizedTab;
