import { Pagination } from "@components/pagination";
import { useDeleteSchoolTypeMutation, useGetListSchoolTypePaginateQuery } from "@redux/queries/admin/admin.school.type";
import { Button, Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterTitleSchoolType from "./components/Filter";
import _ from "lodash";
import { title } from "process";
import HandleForm from "./components/HandleForm";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const SchoolType = () => {
    const [modal, contextHolder] = Modal.useModal();

    const [openHandleForm, setOpenHandleForm] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>(null);
    const [filter, setFilter] = useState<any>({});
    const [clear, setClear] = useState<boolean>(false);
    const [query, setQuery] = useState<any>({
        page: 1,
        limit: 10,
    });

    const { data, isLoading, isFetching } = useGetListSchoolTypePaginateQuery(query);
    const [deleteSchoolType] = useDeleteSchoolTypeMutation();

    const handleDelete = async (id: string) => {
        const result = await deleteSchoolType({
            id: id,
        });

        if ("error" in result) {
            // show error use toast
        }

        if ("data" in result) {
            // show success use toast
        }
    };

    const confirmDeleteRecord = (id: string) => {
        modal.confirm({
            title: "Cảnh báo",
            icon: <ExclamationCircleOutlined />,
            content: "Bạn có chắc chắn muốn xóa bản ghi này?",
            okText: "Xóa",
            cancelText: "Hủy",
            onOk() {
                handleDelete(id);
            },
            centered: true,
        });
    };

    const columns = [
        {
            title: <div className="text-title-table">Tên loại trường học</div>,
            key: "languageTitleVn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[0] && language[0].title;
            },
        },
        {
            title: <div className="text-title-table">School type name</div>,
            key: "languageTitleEn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[1] && language[1].title;
            },
        },
        {
            title: <div className="text-title-table">学校类型名称</div>,
            key: "languageTitleCn",
            dataIndex: "language",
            render: (language: any) => {
                return language && language[2] && language[2].title;
            },
        },

        {
            title: <div className="text-title-table">Action</div>,
            width: 120,
            key: "action",
            render: (text: any, record: any) => {
                return (
                    <div
                        className="group-icon-action"
                        key={record.id}
                    >
                        <button
                            type="button"
                            className="text-green"
                            onClick={() => {
                                setDataEdit(record);
                                setOpenHandleForm(true);
                            }}
                        >
                            <i className="bi-pencil-square"></i>
                        </button>
                        <button
                            type="button"
                            className="text-red"
                            onClick={() => confirmDeleteRecord(record.id)}
                        >
                            <i className="bi-trash"></i>
                        </button>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        const newQuery = _.pickBy(
            {
                ...query,
                ...filter,
                page: 1,
            },
            (item) => item !== "" && item !== null && item !== undefined && item !== "-1"
        );
        setQuery(newQuery);
    }, [filter]);

    useEffect(() => {
        if (clear && "title" in query) {
            const newQuery = _.pickBy({
                page: query.page,
                limit: query.limit,
            });
            setQuery(newQuery);
            setClear(false);
        }
    }, [clear]);

    return (
        <div className="container-fluid padding0">
            <span className="screen-darken"></span>
            <main>
                <section id="content-main">
                    <div className="box-component">
                        <div className="body-component">
                            <div className="group-action-head">
                                <div className="row">
                                    <FilterTitleSchoolType
                                        data={filter}
                                        onSearch={(v: any) => setFilter(v)}
                                        onClear={(v: boolean) => setClear(v)}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="group-btn">
                                            <Button
                                                type="primary"
                                                onClick={() => setOpenHandleForm(true)}
                                            >
                                                Add New
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    // rowSelection={rowSelection}
                                    columns={columns}
                                    dataSource={(data?.items as any) || []}
                                    pagination={false}
                                    rowKey="id"
                                    loading={isLoading || isFetching}
                                    scroll={{ x: 1000 }}
                                    // expandable={{
                                    //     expandedRowRender: (e) => {
                                    //         return (
                                    //             <ShortPost2
                                    //                 id={e?.id}
                                    //                 category={
                                    //                     e?.category?.language?.find((item: any) => item.lang === "vi")
                                    //                         ?.title ||
                                    //                     e?.area?.language?.find((item: any) => item.lang === "vi")
                                    //                         ?.title
                                    //                 }
                                    //                 title={
                                    //                     e?.language?.find((item: any) => item.lang === "vi")?.title ||
                                    //                     ""
                                    //                 }
                                    //                 username={
                                    //                     (e?.creator?.firstName + e?.creator?.firstName).length === 0
                                    //                         ? e?.creator?.userName
                                    //                         : e?.creator?.firstName + e?.creator?.firstName
                                    //                 }
                                    //                 avatar={""}
                                    //                 time={e.createdAt}
                                    //                 slug={
                                    //                     e?.language?.find((item: any) => item.lang === "vi")?.slug || ""
                                    //                 }
                                    //                 content={
                                    //                     e?.language?.find((item: any) => item.lang === "vi")
                                    //                         ?.shortContent || ""
                                    //                 }
                                    //                 key={e.id}
                                    //                 like={e?.like}
                                    //                 comment={e?.like}
                                    //                 save={e?.like}
                                    //                 link={e?.linkDetail}
                                    //                 thumbnail={e?.area?.thumbnail}
                                    //             />
                                    //         );
                                    //     },
                                    // }}
                                />

                                <Pagination
                                    onSizeChange={(size) => setQuery({ ...query, limit: size })}
                                    total={data?.meta.totalItems || 0}
                                    showSize={true}
                                    totalPage={data?.meta.totalPages || 0}
                                    onChangePage={(page) => setQuery({ ...query, page: page })}
                                    defaultCurrentPage={query.page}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <HandleForm
                    openHandleForm={openHandleForm}
                    setOpenHandleForm={(v: boolean) => setOpenHandleForm(v)}
                    dataEdit={dataEdit}
                    setDataEdit={(d: any) => setDataEdit(d)}
                />
            </main>
            {contextHolder}
        </div>
    );
};

export default SchoolType;
