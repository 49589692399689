import ModalCustome from "@components/modal/Modal";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useUpdateScholarshipMutation } from "@redux/queries/admin/admin.scholarship";
import { useGetListScholarshipTypeQuery } from "@redux/queries/admin/admin.scholarship.type";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

interface UpdateScholarshipProps {
    data: IScholarship | null;
    onClose: () => void;
}

const UpdateScholarship: React.FunctionComponent<UpdateScholarshipProps> = (props) => {
    const { data } = props;
    const [updateScholarship, result] = useUpdateScholarshipMutation();
    const { data: list_type, isLoading: isLoadingArea, error: errorArea } = useGetListScholarshipTypeQuery();
    const [open, setOpen] = useState<boolean>(false);
    const closeModal = () => {
        setOpen(false);
        props.onClose();
    };
    const openModal = () => setOpen(true);

    const { showToast } = useToast();
    const formik = useFormik({
        initialValues: {
            id: "",
            title_vi: "",
            title_en: "",
            title_cn: "",
            scholarshipTypeId: "",
            passScore: 0,
            link: "",
        },
        validationSchema: Yup.object({
            title_vi: Yup.string().required("Không được để trống"),
            title_en: Yup.string().required("Không được để trống"),
            title_cn: Yup.string().required("Không được để trống"),
            scholarshipTypeId: Yup.string().required("Không được để trống"),
            passScore: Yup.number()
                .optional()
                .max(99, "Điểm đỗ  nằm trong khoảng 1-99")
                .min(1, "Điểm đỗ  nằm trong khoảng 1-99"),
        }),
        onSubmit: async (values) => {
            let language = [
                {
                    lang: "vi",
                    title: values.title_vi,
                },
                {
                    lang: "en",
                    title: values.title_en,
                },
                {
                    lang: "cn",
                    title: values.title_cn,
                },
            ];

            // const result = await updateScholarship({
            //     id: values.id,
            //     language: language,
            //     scholarshipTypeId: values.scholarshipTypeId,
            //     passScore: +values.passScore,
            //     link: values.link,
            // });
            // if ("data" in result) {
            //     closeModal();
            //     showToast({ ...TOAST_UPDATE_SUCCESS });
            // }
            // if ("error" in result) {
            //     showToast({ ...TOAST_UPDATE_ERROR });
            // }
            // show error use toast
        },
    });

    useEffect(() => {
        if (data) {
            openModal();
            formik.setValues({
                id: data.id,
                title_vi: data.language[0].title,
                title_en: data.language[1].title,
                title_cn: data.language[2].title,
                scholarshipTypeId: data.scholarshipTypeId,
                passScore: +data.passScore,
                link: data.link,
            });
        } else {
            closeModal();
        }
    }, [data]);

    return (
        <>
            <ModalCustome
                open={open}
                onClose={closeModal}
                title="Update Scholarship"
                footer={[
                    <button
                        key="1"
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        className="btn-blue"
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Submit</span>
                    </button>,
                    <button
                        key="2"
                        type="button"
                        onClick={closeModal}
                        className="text-box-gray"
                    >
                        Close
                    </button>,
                ]}
            >
                <form>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_vi"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_vi}
                            />
                            <label>Tên học bổng</label>
                        </div>
                        {formik.touched.title_vi && formik.errors.title_vi ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_vi}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_en"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_en}
                            />
                            <label>Tên tiếng anh</label>
                        </div>
                        {formik.touched.title_en && formik.errors.title_en ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_en}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="title_cn"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title_cn}
                            />
                            <label>Tên tiếng trung</label>
                        </div>
                        {formik.touched.title_cn && formik.errors.title_cn ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.title_cn}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <select
                                className="form-select"
                                name="scholarshipTypeId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.scholarshipTypeId ? formik.values.scholarshipTypeId : ""}
                            >
                                {list_type &&
                                    list_type.map((o, i) => {
                                        return (
                                            <option
                                                key={o.id}
                                                value={o.id}
                                            >
                                                {o.language[0].title}
                                            </option>
                                        );
                                    })}
                            </select>
                            <label>Chọn loại học bổng</label>
                        </div>
                        {formik.touched.scholarshipTypeId && formik.errors.scholarshipTypeId ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.scholarshipTypeId}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="passScore"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.passScore}
                            />
                            <label>Điểm đỗ</label>
                        </div>
                        {formik.touched.passScore && formik.errors.passScore ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.passScore}</p>
                        ) : null}
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="link"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.link}
                            />
                            <label>Link</label>
                        </div>
                        {formik.touched.link && formik.errors.link ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.link}</p>
                        ) : null}
                    </div>
                </form>
            </ModalCustome>
        </>
    );
};

export default UpdateScholarship;
