import TinyMCE from "@components/Editor";
import TabComponent from "@components/Tab";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_CREATE_ERROR, TOAST_CREATE_SUCCESS, TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useGetListSchoolQuery } from "@redux/queries/admin/admin.school";
import { useCreateSchoolFaqMutation, useUpdateSchoolFaqMutation } from "@redux/queries/admin/admin.school.faq";
import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";

interface HandleFormProps {
    openHandleForm: boolean;
    setOpenHandleForm: (visible: boolean) => void;
    dataEdit?: any;
    setDataEdit?: (data: any) => void;
}

const ContentVi = [
    {
        name: "questionVi",
        title: "Câu hỏi",
        data: "",
        require: true,
    },
    {
        name: "answerVi",
        title: "Câu trả lời",
        data: "",
        require: true,
    },
];

const ContentEn = [
    {
        name: "questionEn",
        title: "Question",
        data: "",
        require: true,
    },
    {
        name: "answerEn",
        title: "Answer",
        data: "",
        require: false,
    },
];

const ContentCn = [
    {
        name: "questionCn",
        title: "问题",
        data: "",
        require: false,
    },
    {
        name: "answerCn",
        title: "回答",
        data: "",
        require: false,
    },
];

const HandleForm = ({ openHandleForm, setOpenHandleForm, dataEdit, setDataEdit }: HandleFormProps) => {
    const [form] = Form.useForm();
    const [createSchoolFaq, isLoadingCreate] = useCreateSchoolFaqMutation();
    const [updateSchoolFaq, isLoadingUpdate] = useUpdateSchoolFaqMutation();
    const { showToast } = useToast();
    const { data: list_school, isLoading, isFetching } = useGetListSchoolQuery();

    const [introduceVi, setIntroduceVi] = useState<any>("");
    const [introduceEn, setIntroduceEn] = useState<any>("");
    const [introduceCn, setIntroduceCn] = useState<any>("");

    useEffect(() => {
        if (dataEdit) {
            console.log(dataEdit);
            const contentQuestionCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.question || "";
            const contentQuestionEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.question || "";
            const contentQuestionVi =
                dataEdit?.language?.find((item: any) => item.lang === "vi")?.question || dataEdit?.question || "";

            const contentAnswerCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.answer || "";
            const contentAnswerEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.answer || "";
            const contentAnswerVi =
                dataEdit?.language?.find((item: any) => item.lang === "vi")?.answer || dataEdit?.answer || "";

            const titleVi = dataEdit?.language?.find((item: any) => item.lang === "vi")?.title || dataEdit?.title;
            const titleEn = dataEdit?.language?.find((item: any) => item.lang === "en")?.title;
            const titleCn = dataEdit?.language?.find((item: any) => item.lang === "cn")?.title;

            form.setFieldsValue({
                schoolId: dataEdit.school.id,
                questionVi: contentQuestionVi,
                questionEn: contentQuestionEn,
                questionCn: contentQuestionCn,
                answerVi: contentAnswerVi,
                answerEn: contentAnswerEn,
                answerCn: contentAnswerCn,
                titleVi,
                titleEn,
                titleCn,
            });
        } else {
            form.resetFields();
        }
    }, [dataEdit]);

    const onFinish = async (values: any) => {
        const language = [
            {
                lang: "vi",
                title:
                    values.titleVi ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.title ||
                    dataEdit?.title,
                question:
                    values.questionVi ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.question ||
                    dataEdit?.question,
                answer:
                    values.answerVi ||
                    dataEdit?.language?.find((item: any) => item.lang === "vi")?.answer ||
                    dataEdit?.answer,
            },
            {
                lang: "en",
                title: values.titleEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.title,
                question: values.questionEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.question,
                answer: values.answerEn || dataEdit?.language?.find((item: any) => item.lang === "en")?.answer,
            },
            {
                lang: "cn",
                title: values.titleCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.title,
                question: values.questionCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.question,
                answer: values.answerCn || dataEdit?.language?.find((item: any) => item.lang === "cn")?.answer,
            },
        ];

        const result = dataEdit
            ? await updateSchoolFaq({
                  id: dataEdit.id,
                  language: language,
                  question: values.questionVi,
                  answer: values.answerVi,
                  ...values,
              })
            : await createSchoolFaq({
                  language: language,
                  question: values.questionVi,
                  answer: values.answerVi,
                  ...values,
              });
        if ("error" in result) {
            dataEdit ? showToast(TOAST_UPDATE_ERROR) : showToast(TOAST_CREATE_ERROR);
        }
        if ("data" in result) {
            dataEdit ? showToast(TOAST_UPDATE_SUCCESS) : showToast(TOAST_CREATE_SUCCESS);
            form.resetFields();
            setDataEdit && setDataEdit(null);
            setOpenHandleForm(false);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <Modal
                open={openHandleForm}
                onCancel={() => {
                    form.resetFields();
                    setOpenHandleForm(false);
                    setDataEdit && setDataEdit(null);
                }}
                title={dataEdit ? "Update School FAQ" : "Add School FAQ"}
                okText={dataEdit ? "Update" : "Add"}
                onOk={() => form.submit()}
                width={900}
            >
                <div className="body-component">
                    <div className="form-group">
                        <div className="form-floating">
                            <Form.Item
                                name="schoolId"
                                className="form-floating"
                                rules={[
                                    {
                                        required: true,
                                        message: "Thông tin bắt buộc",
                                    },
                                ]}
                            >
                                <Select
                                    className="form-control form-floating"
                                    bordered={false}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {list_school &&
                                        list_school.map((o: any, i: any) => {
                                            return (
                                                <Select.Option
                                                    key={o.id}
                                                    value={o.id}
                                                >
                                                    {o.language[0].name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                            <label>Trường</label>
                        </div>
                    </div>
                    <TabComponent
                        setIntroduceVi={(e) => setIntroduceVi(e)}
                        setIntroduceEn={(e) => setIntroduceEn(e)}
                        setIntroduceCn={(e) => setIntroduceCn(e)}
                        nameContentVi={ContentVi}
                        nameContentEn={ContentEn}
                        nameContentCn={ContentCn}
                        titleVi="titleVi"
                        titleEn="titleEn"
                        titleCn="titleCn"
                        form={form}
                    />
                </div>
            </Modal>
        </Form>
    );
};

export default HandleForm;
