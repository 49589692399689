export enum TYPE_ATTRIBUTE {
    ECONOMIC_ZONE = "ECONOMIC_ZONE",
    AREA = "AREA",
    SNOW = "SNOW",
    SNOW_YES = "SNOW_YES",
}

export const ATTRIBUTE_OPTIONS_ECONOMIC_ZONE = [
    { value: TYPE_ATTRIBUTE.ECONOMIC_ZONE, labelVi: "Miền Bắc", labelCn: "东北", labelEn: "Northeast" },
    { value: TYPE_ATTRIBUTE.ECONOMIC_ZONE, labelVi: "Miền Tây", labelCn: "西部", labelEn: "West" },
    { value: TYPE_ATTRIBUTE.ECONOMIC_ZONE, labelVi: "Miền Trung", labelCn: "中部", labelEn: "Central" },
    { value: TYPE_ATTRIBUTE.ECONOMIC_ZONE, labelVi: "Miền Đông", labelCn: "东部", labelEn: "East" },
];

export const ATTRIBUTE_OPTIONS_AREA = [
    { value: TYPE_ATTRIBUTE.AREA, labelVi: "Hoa Bắc", labelCn: "华北地区", labelEn: "North China" },
    { value: TYPE_ATTRIBUTE.AREA, labelVi: "Đông Bắc", labelCn: "东北地区", labelEn: "Northeast Region" },
    { value: TYPE_ATTRIBUTE.AREA, labelVi: "Hoa Đông", labelCn: "华东地区", labelEn: "East China" },
    { value: TYPE_ATTRIBUTE.AREA, labelVi: "Trung Nam", labelCn: "中南地区", labelEn: "South Central" },
    { value: TYPE_ATTRIBUTE.AREA, labelVi: " Tây Nam", labelCn: "西南地区", labelEn: " Southwest Region" },
    { value: TYPE_ATTRIBUTE.AREA, labelVi: "Tây Bắc", labelCn: "西北地区", labelEn: "Northwest Territories" },
];

export const ATTRIBUTE_OPTIONS_SNOW = [
    {
        value: TYPE_ATTRIBUTE.SNOW,
        labelVi: "Tuyết rơi quanh năm",
        labelCn: "常年多雪",
        labelEn: "Snowy all year round",
    },
    { value: TYPE_ATTRIBUTE.SNOW, labelVi: "Tuyết rơi nhiều", labelCn: "多雪", labelEn: "Snowy" },
    { value: TYPE_ATTRIBUTE.SNOW, labelVi: "Tuyết rơi ít", labelCn: "降雪", labelEn: "Snowfall" },
    {
        value: TYPE_ATTRIBUTE.SNOW,
        labelVi: "Thi thoảng có tuyết",
        labelCn: "偶尔降雪",
        labelEn: "Occasional snowfall",
    },
    { value: TYPE_ATTRIBUTE.SNOW, labelVi: "Không", labelCn: "否", labelEn: "None" },
];
