import TinyMCE from "@components/Editor";
import { validateNoSpaces } from "@utils/common";
import { Col, Form, Input, Row, Select, Tabs } from "antd";
import { useRef, useState } from "react";
import item01 from "@assets/images/item-01.jpg";

interface IProps {
    // introduceVi: string;
    // introduceEn: string;
    // introduceCn: string;

    setIntroduceVi?: (value: string) => void;
    setIntroduceEn?: (value: string) => void;
    setIntroduceCn?: (value: string) => void;

    titleVi: string;
    titleEn: string;
    titleCn: string;

    nameTextVi?: Array<{ name: string; require: boolean; title: string }>;
    nameTextEn?: Array<{ name: string; require: boolean; title: string }>;
    nameTextCn?: Array<{ name: string; require: boolean; title: string }>;

    nameNumberVi?: Array<{ name: string; require: boolean; title: string }>;
    nameNumberEn?: Array<{ name: string; require: boolean; title: string }>;
    nameNumberCn?: Array<{ name: string; require: boolean; title: string }>;

    nameSelectVi?: Array<{ name: string; require: boolean; title: string; data: any; multi: boolean }>;
    nameSelectEn?: Array<{ name: string; require: boolean; title: string; data: any; multi: boolean }>;
    nameSelectCn?: Array<{ name: string; require: boolean; title: string; data: any; multi: boolean }>;

    nameContentVi?: Array<{ name: string; title: string; data: any; require: boolean }>;
    nameContentEn?: Array<{ name: string; title: string; data: any; require: boolean }>;
    nameContentCn?: Array<{ name: string; title: string; data: any; require: boolean }>;

    dataEdit?: any;
    setDataEdit?: (value: string) => void;
    form: any;
}

const TabComponent = (props: IProps) => {
    const {
        // introduceVi,
        // introduceEn,
        // introduceCn,
        setIntroduceVi,
        setIntroduceEn,
        setIntroduceCn,
        titleVi,
        titleEn,
        titleCn,
        nameTextVi,
        nameTextEn,
        nameTextCn,
        nameNumberVi,
        nameNumberEn,
        nameNumberCn,
        nameSelectVi,
        nameSelectEn,
        nameSelectCn,
        nameContentVi,
        nameContentEn,
        nameContentCn,
    } = props;

    const initialItems = [] as any[];

    const [activeKey, setActiveKey] = useState(titleVi);
    const [items, setItems] = useState(initialItems);

    const newTabIndex = useRef(0);

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };
    return (
        <div className="">
            <Tabs
                type="editable-card"
                onChange={onChange}
                activeKey={activeKey}
                onEdit={onEdit}
                items={[
                    {
                        label: "Tiếng Việt",
                        children: (
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={titleVi}
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Thông tin bắt buộc",
                                                },
                                                {
                                                    validator: (rule, value, callback) =>
                                                        validateNoSpaces(rule, value, callback, "vi"),
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Tiêu đề</label>
                                    </div>
                                </div>
                                <Row gutter={20}>
                                    {nameTextVi &&
                                        nameTextVi.length > 0 &&
                                        nameTextVi.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Thông tin bắt buộc",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "vi"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameNumberVi &&
                                        nameNumberVi.length > 0 &&
                                        nameNumberVi.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Thông tin bắt buộc",
                                                                              },
                                                                              {
                                                                                  pattern: /^[0-9]*$/,
                                                                                  message: "Phải nhập số",
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameSelectVi &&
                                        nameSelectVi.length > 0 &&
                                        nameSelectVi.map((item: any, index: number) => {
                                            const { name, require, title, data, multi } = item;

                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Thông tin bắt buộc",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "vi"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : []
                                                                }
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    showSearch
                                                                    allowClear
                                                                    {...(multi ? { mode: "multiple" } : {})}
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {data &&
                                                                        data?.map((o: any, i: number) => (
                                                                            <Select.Option
                                                                                key={o.id || i}
                                                                                value={o.name || o.labelVi}
                                                                            >
                                                                                {o.name || o.labelVi}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>

                                {nameContentVi &&
                                    nameContentVi.length > 0 &&
                                    nameContentVi.map((item: any, index: number) => {
                                        const { name, title, data, require } = item;

                                        return (
                                            <div
                                                className="form-group"
                                                key={index}
                                            >
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={name}
                                                        rules={
                                                            require
                                                                ? [
                                                                      {
                                                                          required: true,
                                                                          message: "Thông tin bắt buộc",
                                                                      },
                                                                      {
                                                                          validator: (rule, value, callback) =>
                                                                              validateNoSpaces(
                                                                                  rule,
                                                                                  value,
                                                                                  callback,
                                                                                  "vi"
                                                                              ),
                                                                      },
                                                                  ]
                                                                : undefined
                                                        }
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceVi}
                                                    onChange={(value: any) => {
                                                        setIntroduceVi(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                        <TinyMCE
                                                            value={data}
                                                            onChange={(value: string) =>
                                                                setIntroduceVi && setIntroduceVi(value)
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <label>{title}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ),
                        key: titleVi,
                        closable: false,
                    },
                    {
                        label: "English",
                        children: (
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={titleEn}
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Field required",
                                                },
                                                {
                                                    validator: (rule, value, callback) =>
                                                        validateNoSpaces(rule, value, callback, "en"),
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>Title</label>
                                    </div>
                                </div>

                                <Row gutter={20}>
                                    {nameTextEn &&
                                        nameTextEn.length > 0 &&
                                        nameTextEn.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Field required",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "en"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameNumberEn &&
                                        nameNumberEn.length > 0 &&
                                        nameNumberEn.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Field required",
                                                                              },
                                                                              {
                                                                                  pattern: /^[0-9]*$/,
                                                                                  message: "Number required",
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameSelectEn &&
                                        nameSelectEn.length > 0 &&
                                        nameSelectEn.map((item: any, index: number) => {
                                            const { name, require, title, data, multi } = item;

                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "Field required",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "en"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : []
                                                                }
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    {...(multi ? { mode: "multiple" } : {})}
                                                                    showSearch
                                                                    allowClear
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .indexOf(input.toLowerCase()) >= 0
                                                                    }
                                                                >
                                                                    {data &&
                                                                        data?.map((o: any, i: number) => (
                                                                            <Select.Option
                                                                                key={o.id || i}
                                                                                value={o.name || o.labelEn}
                                                                            >
                                                                                {o.name || o.labelEn}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>

                                {nameContentEn &&
                                    nameContentEn.length > 0 &&
                                    nameContentEn.map((item: any, index: number) => {
                                        const { name, title, data, require } = item;

                                        return (
                                            <div
                                                className="form-group"
                                                key={index}
                                            >
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={name}
                                                        rules={
                                                            require
                                                                ? [
                                                                      {
                                                                          required: true,
                                                                          message: "Field required",
                                                                      },
                                                                      {
                                                                          validator: (rule, value, callback) =>
                                                                              validateNoSpaces(
                                                                                  rule,
                                                                                  value,
                                                                                  callback,
                                                                                  "en"
                                                                              ),
                                                                      },
                                                                  ]
                                                                : undefined
                                                        }
                                                        className="form-floating"
                                                    >
                                                        {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceEn}
                                                    onChange={(value: any) => {
                                                        setIntroduceEn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                        <TinyMCE
                                                            value={data}
                                                            onChange={(value: string) =>
                                                                setIntroduceEn && setIntroduceEn(value)
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <label>{title}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ),
                        key: titleEn,
                        closable: false,
                    },
                    {
                        label: "中国人",
                        children: (
                            <div className="body-component">
                                <div className="form-group">
                                    <div className="form-floating">
                                        <Form.Item
                                            name={titleCn}
                                            className="form-floating"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "必填字段",
                                                },
                                                {
                                                    validator: (rule, value, callback) =>
                                                        validateNoSpaces(rule, value, callback, "cn"),
                                                },
                                            ]}
                                        >
                                            <Input
                                                type="text"
                                                className="form-floating no-icon"
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <label>标题</label>
                                    </div>
                                </div>

                                <Row gutter={20}>
                                    {nameTextCn &&
                                        nameTextCn.length > 0 &&
                                        nameTextCn.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "必填字段",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "cn"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameNumberCn &&
                                        nameNumberCn.length > 0 &&
                                        nameNumberCn.map((item: any, index: number) => {
                                            const { name, require, title } = item;
                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "必填字段",
                                                                              },
                                                                              {
                                                                                  pattern: /^[0-9]*$/,
                                                                                  message: "Number required",
                                                                              },
                                                                          ]
                                                                        : undefined
                                                                }
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    className="form-floating no-icon"
                                                                    placeholder=""
                                                                />
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}

                                    {nameSelectCn &&
                                        nameSelectCn.length > 0 &&
                                        nameSelectCn.map((item: any, index: number) => {
                                            const { name, require, title, data, multi } = item;

                                            return (
                                                <Col
                                                    span={12}
                                                    key={index}
                                                >
                                                    <div className="form-group">
                                                        <div className="form-floating">
                                                            <Form.Item
                                                                name={name}
                                                                className="form-floating"
                                                                rules={
                                                                    require
                                                                        ? [
                                                                              {
                                                                                  required: true,
                                                                                  message: "必填字段",
                                                                              },
                                                                              {
                                                                                  validator: (rule, value, callback) =>
                                                                                      validateNoSpaces(
                                                                                          rule,
                                                                                          value,
                                                                                          callback,
                                                                                          "cn"
                                                                                      ),
                                                                              },
                                                                          ]
                                                                        : []
                                                                }
                                                            >
                                                                <Select
                                                                    className="form-control form-floating"
                                                                    bordered={false}
                                                                    {...(multi ? { mode: "multiple" } : {})}
                                                                    showSearch
                                                                    allowClear
                                                                    filterOption={(input, option) =>
                                                                        (option?.children as any)
                                                                            ?.toLowerCase()
                                                                            .includes(input.toLowerCase())
                                                                    }
                                                                >
                                                                    {Array.isArray(data) &&
                                                                        data.map((o: any, i: number) => (
                                                                            <Select.Option
                                                                                key={o.id || i}
                                                                                value={o.name || o.labelCn}
                                                                            >
                                                                                {o.name || o.labelCn}
                                                                            </Select.Option>
                                                                        ))}
                                                                </Select>
                                                            </Form.Item>
                                                            <label>{title}</label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>

                                {nameContentCn &&
                                    nameContentCn.length > 0 &&
                                    nameContentCn.map((item: any, index: number) => {
                                        const { name, title, data, require } = item;

                                        return (
                                            <div
                                                className="form-group"
                                                key={index}
                                            >
                                                <div className="form-floating">
                                                    <Form.Item
                                                        name={name}
                                                        className="form-floating"
                                                        rules={
                                                            require
                                                                ? [
                                                                      {
                                                                          required: true,
                                                                          message: "必填字段",
                                                                      },
                                                                      {
                                                                          validator: (rule, value, callback) =>
                                                                              validateNoSpaces(
                                                                                  rule,
                                                                                  value,
                                                                                  callback,
                                                                                  "cn"
                                                                              ),
                                                                      },
                                                                  ]
                                                                : undefined
                                                        }
                                                    >
                                                        {/* <ReactQuill
                                                    className="text-editor"
                                                    value={introduceCn}
                                                    onChange={(value: any) => {
                                                        setIntroduceCn(value);
                                                    }}
                                                    formats={formats}
                                                    modules={modules}
                                                /> */}
                                                        <TinyMCE
                                                            value={data}
                                                            onChange={(value: string) =>
                                                                setIntroduceCn && setIntroduceCn(value)
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <label>{title}</label>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        ),
                        key: titleCn,
                        closable: false,
                    },
                ]}
            />
        </div>
    );
};

export default TabComponent;
