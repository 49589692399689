export enum TYPE_SELECT_ENUM {
    CITY_SIZE = "CITY_SIZE",
    CITY_CLASSIFICATION = "CITY_CLASSIFICATION",
    ADMINISTRATIVE_LEVEL = "ADMINISTRATIVE_LEVEL",
}

export enum TITLE_CITY_SIZE {
    VI = "Quy mô thành phố",
    EN = "City size",
    CN = "城市规模",
}

export enum TITLE_CITY_CLASSIFICATION {
    VI = "Phân cấp thành phố",
    EN = "New city classification",
    CN = "城市新分级",
}

export enum TITLE_ADMINISTRATIVE_LEVEL {
    VI = "Cấp hành chính",
    EN = "Administrative level",
    CN = "行政等级",
}

export const SELECT_CITY_SIZE = [
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Siêu đô thị",
        labelCn: "超大城市",
        labelEn: "Megacities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Siêu đô thị",
        labelCn: "特大城市",
        labelEn: "Megacities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Thành phố lớn loại I",
        labelCn: "I型大城市",
        labelEn: "Type I megacities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Thành phố lớn loại II",
        labelCn: "II型大城市",
        labelEn: "Type II megacities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Thành phố vừa",
        labelCn: "中等城市",
        labelEn: "Medium-sized cities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Thành phố nhỏ loại I",
        labelCn: "一型小城市",
        labelEn: "Type I small city",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_SIZE,
        labelVi: "Thành phố nhỏ loại II",
        labelCn: "二型小城市",
        labelEn: "Type II small city",
    },
];

export const SELECT_CITY_CLASSIFICATION = [
    {
        value: TYPE_SELECT_ENUM.CITY_CLASSIFICATION,
        labelVi: "TP Hạng 1",
        labelCn: "一线城市",
        labelEn: "First-tier cities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_CLASSIFICATION,
        labelVi: "TP Hạng 2",
        labelCn: "二线城市",
        labelEn: "Second-tier cities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_CLASSIFICATION,
        labelVi: "TP Hạng 3",
        labelCn: "三线城市",
        labelEn: "Third-tier cities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_CLASSIFICATION,
        labelVi: "TP Hạng 4",
        labelCn: "四线城市",
        labelEn: "Fourth-tier cities",
    },
    {
        value: TYPE_SELECT_ENUM.CITY_CLASSIFICATION,
        labelVi: "TP Hạng 5",
        labelCn: "五线城市",
        labelEn: "Fifth-tier cities",
    },
];

export const SELECT_ADMINISTRATIVE_LEVEL = [
    {
        value: TYPE_SELECT_ENUM.ADMINISTRATIVE_LEVEL,
        labelVi: "TP trực thuộc TW",
        labelCn: "直辖市",
        labelEn: "Municipalities",
    },
    {
        value: TYPE_SELECT_ENUM.ADMINISTRATIVE_LEVEL,
        labelVi: "Thủ phủ tỉnh",
        labelCn: "省会",
        labelEn: "Provincial capital",
    },
    {
        value: TYPE_SELECT_ENUM.ADMINISTRATIVE_LEVEL,
        labelVi: "TP cấp tỉnh",
        labelCn: "地级市",
        labelEn: "Prefectural-level city",
    },
    {
        value: TYPE_SELECT_ENUM.ADMINISTRATIVE_LEVEL,
        labelVi: "TP cấp huyện",
        labelCn: "县级市",
        labelEn: "County-level city",
    },
];
