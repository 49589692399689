import ModalCustome from "@components/modal/Modal";
import { useToast } from "@components/toast/ToastProvider";
import { TOAST_UPDATE_ERROR, TOAST_UPDATE_SUCCESS } from "@consts";
import { useUpdateProjectMutation } from "@redux/queries/admin/admin.project";
import { Tabs } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

interface UpdateProjectProps {
    data: IProject | null;
    onClose: () => void;
}

const UpdateProject: React.FunctionComponent<UpdateProjectProps> = (props) => {
    const { data } = props;
    const [updateProject, result] = useUpdateProjectMutation();

    const initialItems = [] as any[];

    const newTabIndex = useRef(0);

    const [open, setOpen] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState("titleVi");
    const [items, setItems] = useState(initialItems);

    const closeModal = () => {
        setOpen(false);
        props.onClose();
    };
    const openModal = () => setOpen(true);

    const { showToast } = useToast();

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({ label: "New Tab", children: <>Content of new Tab</>, key: newActiveKey, closable: true });
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey: any) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
    };

    const onEdit = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: "add" | "remove") => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    const formik = useFormik({
        initialValues: {
            id: "",
            nameVi: "",
            nameEn: "",
            nameCn: "",
            description: "",
        },
        validationSchema: Yup.object({
            nameVi: Yup.string().required("Không được để trống"),
            nameEn: Yup.string().required("Field required"),
            nameCn: Yup.string().required("必填字段"),
            description: Yup.string().required("Không được để trống"),
        }),
        onSubmit: async (values) => {
            const language = [
                { lang: "vi", name: values.nameVi },
                { lang: "en", name: values.nameEn },
                { lang: "cn", name: values.nameCn },
            ];
            const result = await updateProject({
                id: values.id,
                language: language,
                name: values.nameVi,
                description: values.description,
            });
            if ("data" in result) {
                closeModal();
                showToast({ ...TOAST_UPDATE_SUCCESS });
            }
            if ("error" in result) {
                showToast({ ...TOAST_UPDATE_ERROR });
            }
            // show error use toast
        },
    });

    useEffect(() => {
        if (data) {
            openModal();
            const nameVi = data?.language?.find((item) => item.lang === "vi")?.name || "";
            const nameEn = data?.language?.find((item) => item.lang === "en")?.name || "";
            const nameCn = data?.language?.find((item) => item.lang === "cn")?.name || "";
            formik.setValues({
                id: data.id,
                nameVi,
                nameEn,
                nameCn,
                description: data.description,
            });
        } else {
            closeModal();
        }
    }, [data]);

    return (
        <>
            <ModalCustome
                open={open}
                onClose={closeModal}
                title="Update Project"
                footer={[
                    <button
                        key="1"
                        type="submit"
                        onClick={() => formik.handleSubmit()}
                        className="btn-blue"
                    >
                        {result.isLoading && (
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        <span className="ps-2">Submit</span>
                    </button>,
                    <button
                        key="2"
                        type="button"
                        onClick={closeModal}
                        className="text-box-gray"
                    >
                        Close
                    </button>,
                ]}
            >
                <form>
                    <div className="form-group">
                        <div
                            className="tab-pane fade show active"
                            id="default-tab-pane"
                            role="tabpanel"
                            aria-labelledby="default-tab"
                            tabIndex={0}
                        >
                            <Tabs
                                type="editable-card"
                                onChange={onChange}
                                activeKey={activeKey}
                                onEdit={onEdit}
                                items={[
                                    {
                                        label: "Tiếng Việt",
                                        children: (
                                            <div>
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="nameVi"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.nameVi}
                                                    />
                                                    <label>Tên dự án</label>
                                                </div>
                                                {formik.touched.nameVi && formik.errors.nameVi ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.nameVi}</p>
                                                ) : null}
                                            </div>
                                        ),
                                        key: "titleVi",
                                        closable: false,
                                    },
                                    {
                                        label: "English",
                                        children: (
                                            <div>
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="nameEn"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.nameEn}
                                                    />
                                                    <label>Project Name</label>
                                                </div>
                                                {formik.touched.nameEn && formik.errors.nameEn ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.nameEn}</p>
                                                ) : null}
                                            </div>
                                        ),
                                        key: "titleEn",
                                        closable: false,
                                    },
                                    {
                                        label: "中国人",
                                        children: (
                                            <div>
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder=""
                                                        name="nameCn"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.nameCn}
                                                    />
                                                    <label>项目名称</label>
                                                </div>
                                                {formik.touched.nameCn && formik.errors.nameCn ? (
                                                    // @ts-ignore
                                                    <p className="text-red">{formik?.errors?.nameCn}</p>
                                                ) : null}
                                            </div>
                                        ),
                                        key: "titleCn",
                                        closable: false,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                placeholder=""
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                            />
                            <label>Mô Tả</label>
                        </div>
                        {formik.touched.description && formik.errors.description ? (
                            // @ts-ignore
                            <p className="text-red">{formik?.errors?.description}</p>
                        ) : null}
                    </div>
                </form>
            </ModalCustome>
        </>
    );
};

export default UpdateProject;
